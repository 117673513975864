import { Brand } from './Brand'

export interface UsersRequest {
    q?: string
    brand?: Brand
    page: number
    pageSize: number
    filters: UserListFilter[]
}

export interface UsersExportRequest {
    q?: string
    brand?: Brand
    filters: UserListFilter[]
}

export enum UsersExportResponseStatuses {
    CREATED = "CREATED",
    IN_PROGRESS = "IN_PROGRESS",
    READY = "READY",
    FAILED = "FAILED",
}

export type UsersExportResponseStatus = keyof typeof UsersExportResponseStatuses

export enum UserListFilter {
    INACTIVE = 'INACTIVE',
    LAST_LOGIN = 'LAST_LOGIN',
}

export const userListFilterNames: Record<UserListFilter, string> = {
    [UserListFilter.INACTIVE]: 'Inactive users',
    [UserListFilter.LAST_LOGIN]: 'Last login > 90 days',
}

export const usersRequestDefault: UsersRequest = {
    q: '',
    page: 0,
    pageSize: 20,
    filters: [],
}
