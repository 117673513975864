import React, { FC } from 'react'

interface FormProps {
    onSubmit: () => void
}

export const Form: FC<FormProps> = ({ children, onSubmit }) => (
    <form
        onSubmit={event => {
            event.preventDefault()
            onSubmit()
        }}
    >
        {children}
    </form>
)
