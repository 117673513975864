import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { replaceItemAt } from '../../helpers/collections'
import { useActions } from '../../hooks/useActions'
import { useRouter } from '../../hooks/useRouter'
import { BrandFormData } from '../../screens/users/details/components/UserBrandForm'
import { PortalFormData } from '../../screens/users/details/components/UserPortalForm'
import { useAuth } from '../auth/authHooks'
import { UsersRequest } from '../../common/user/UserRequest'
import {
    BrandRolesAssignment,
    groupedBrandRoles,
    groupedPortalRoles,
    PortalRolesAssignment,
    ungroupedBrandRoles,
    ungroupedPortalRoles,
    uniqBrandRole,
    uniqPortalRoles,
    User,
} from '../../common/user/User'
import {
    TmsAccountOptionStatus,
    TmsAccountsRequestQuery,
    toTmsAccountNumbers,
} from '../../common/user/TmsAccountWithName'
import {
    BrandRole,
    BrandRoleBusiness,
    BrandRoleDocuments,
    getBrandRoles,
} from '../../common/user/BrandRole'
import { allBrands, Brand } from '../../common/user/Brand'
import {
    BrandRoleAssignmentOption,
    hasAccessToBrandInOptions,
} from '../../services/users/BrandRoleAssignmentOption'
import { allPortals, Portal } from '../../common/user/Portal'
import { allPortalsRoles, PortalRole } from '../../common/user/PortalRole'
import {
    hasAccessToPortalInOptions,
    PortalRoleAssignmentOption,
} from '../../common/user/PortalRoleAssignmentOption'
import { UsersStateAware } from './usersState'
import { filterSelectors } from './usersSelectors'
import {
    addAndVerifyTmsAccount,
    clearTmsAccountsOptions,
    deleteUser,
    exportUsers,
    load,
    loadUser,
    loadUserAccounts,
    resetTmsAccountsOptions,
    setFilters,
    update,
    verifyTmsAccounts,
} from './usersActions'

export const useUsers = () => {
    const actions = useActions({
        loadUsers: load.started,
        exportUsers,
        setFilters,
    })
    const search = useSelector<UsersStateAware, UsersRequest>(filterSelectors)
    const usersState = useSelector((state: UsersStateAware) => state.users)

    return {
        ...usersState,
        ...actions,
        search,
        setSearch: actions.setFilters,
    }
}

export const useUser = (id: string) => {
    const actions = useActions({
        loadUser: loadUser.started,
    })
    const { currentUser } = useSelector((state: UsersStateAware) => state.users)

    useEffect(() => {
        if (currentUser?.objectId !== id) {
            actions.loadUser(id)
        }
    }, [id])

    return {
        currentUser,
    }
}

export const useRouteUser = () => {
    const { params } = useRouter()
    const { currentUser } = useUser(params.id)

    return {
        routeUser: currentUser,
    }
}

export const useUserActions = () => {
    const actions = useActions({
        updateUser: update.started,
        deleteUser: deleteUser.started,
    })

    return {
        ...actions,
    }
}

export const useUsersAccounts = (user: User) => {
    const actions = useActions({
        loadUserAccountsAction: loadUserAccounts.started,
    })
    const { loadUserAccountsInProgress } = useSelector((state: UsersStateAware) => state.users)

    useEffect(() => {
        actions.loadUserAccountsAction({ accountNumber: user.tmsAccounts.map(a => a.number) })
    }, [user.tmsAccounts])

    return {
        loadUserAccountsInProgress,
    }
}

export const useTmsAccountsOptions = () => {
    const actions = useActions({
        verifyTmsAccounts: verifyTmsAccounts.started,
        addAndVerifyTmsAccount: addAndVerifyTmsAccount.started,
        resetTmsAccountsOptions,
        clearTmsAccountsOptions,
    })
    const { tmsAccountsOptions, tmsVerificationInProgress, tmsVerificationFailed } = useSelector(
        (state: UsersStateAware) => state.users,
    )

    const verifyPendingTmsAccounts = () => {
        const accountsQuery: TmsAccountsRequestQuery = {
            accountNumber: tmsAccountsOptions
                .filter(a => a.status === TmsAccountOptionStatus.Pending)
                .map(a => a.number),
        }
        actions.verifyTmsAccounts(accountsQuery)
    }
    const addAndVerifyTmsAccountByNumber = (accountNumber: string) => {
        const accountNumbers = toTmsAccountNumbers(accountNumber)

        const accountsQuery: TmsAccountsRequestQuery = {
            accountNumber: accountNumbers,
        }
        actions.addAndVerifyTmsAccount(accountsQuery)
    }

    return {
        tmsAccountsOptions,
        tmsVerificationInProgress,
        tmsVerificationFailed,
        verifyPendingTmsAccounts,
        addAndVerifyTmsAccountByNumber,
        resetTmsAccountsOptions: actions.resetTmsAccountsOptions,
        clearTmsAccountsOptions: actions.clearTmsAccountsOptions,
    }
}

export const useAvailableBrands = () => {
    const { availableBrands } = useAuth()
    const brandOptions = allBrands().filter(b => availableBrands.includes(b.mainBrand))

    const optionsForBrand = (brand: Brand): BrandRoleAssignmentOption => {
        const roleOptions = getBrandRoles(brand.mainBrand, brand.subBrand)
        return {
            brand,
            roles: {
                documents: roleOptions.filter(
                    r => BrandRoleDocuments.includes(r) && r !== BrandRole.BrandAdmin,
                ),
                business: roleOptions.filter(
                    r => BrandRoleBusiness.includes(r) && r !== BrandRole.BrandAdmin,
                ),
            },
        }
    }

    const optionsForUser = () =>
        brandOptions
            .map(brand => optionsForBrand(brand))
            .filter(o => o.roles.documents.length && o.roles.business.length)

    const options = optionsForUser()

    const canEditBrandRole = (brandRole: BrandRolesAssignment) =>
        hasAccessToBrandInOptions(options, brandRole.brand.mainBrand)

    return {
        brandRolesOptions: options,
        canEditBrandRole,
    }
}

export const useAvailablePortals = () => {
    const { availablePortals } = useAuth()
    const portalOptions = allPortals.filter(portal => availablePortals.includes(portal))

    const optionsForPortal = (portal: Portal): PortalRoleAssignmentOption => ({
        portal,
        roles: allPortalsRoles.filter(r => r !== PortalRole.PortalAdmin),
    })
    const optionsForUser = () =>
        portalOptions.map(portal => optionsForPortal(portal)).filter(o => o.roles.length)

    const options = optionsForUser()

    const canEditPortalRole = (portalRole: PortalRolesAssignment) =>
        hasAccessToPortalInOptions(options, portalRole.portal)

    return {
        portalRolesOptions: options,
        canEditPortalRole,
    }
}

export const usePortalActions = (user: User) => {
    const { updateUser } = useUserActions()

    const groupedUserPortalRoles = groupedPortalRoles(user.portalRoles)

    const updateUserPortals = ({ roles, portal }: PortalFormData, index: number, actionType: 'portal' | 'tms') => {
        const updatedGroupedUserPortalRoles = replaceItemAt(
            groupedUserPortalRoles,
            { portal, roles },
            index,
        )
        const updatedUserPortalRoles = ungroupedPortalRoles(updatedGroupedUserPortalRoles)        
        updateUser({
            actionType,
            ...user,
            portalRoles: uniqPortalRoles(updatedUserPortalRoles),
        })
    }

    const removeUserPortal = (index: number, actionType: 'portal' | 'tms') => {
        const updatedGroupedUserPortalRoles = groupedUserPortalRoles.filter((_, i) => i !== index)
        const updatedUserPortalRoles = ungroupedPortalRoles(updatedGroupedUserPortalRoles)
        updateUser({
            actionType,
            ...user,
            portalRoles: updatedUserPortalRoles,
        })
    }

    const addUserPortal = ({ roles, portal }: PortalFormData, actionType: 'portal' | 'tms') => {
        const newUserPortalRoles = roles.map(role => ({ portal, role }))
        updateUser({
            actionType,
            ...user,
            portalRoles: uniqPortalRoles([...user.portalRoles, ...newUserPortalRoles]),
        })
    }

    return {
        updateUserPortals,
        removeUserPortal,
        addUserPortal,
    }
}
export const useBrandActions = (user: User) => {
    const { updateUser } = useUserActions()
    const groupedUserBrandRoles: BrandRolesAssignment[] = groupedBrandRoles(user.brandRoles)

    const updateUserBrands = ({ roles, brand }: BrandFormData, index: number, actionType: 'brand' | 'tms') => {
        const updatedGroupedUserBrandRoles = replaceItemAt(
            groupedUserBrandRoles,
            { brand, roles },
            index,
        )
        const updatedUserBrandRoles = ungroupedBrandRoles(updatedGroupedUserBrandRoles)
        updateUser({
            actionType,
            objectId: user.objectId,
            brandRoles: uniqBrandRole(updatedUserBrandRoles),
            portalRoles: user.portalRoles,
            roles: user.roles,
            tmsAccounts: user.tmsAccounts,
            requestedTmsAccountNumbers: user.requestedTmsAccountNumbers,
        })
    }
    const addUserBrand = ({ roles, brand }: BrandFormData, actionType: 'brand' | 'tms') => {
        const newUserBrandRoles = roles.map(role => ({ brand, role }))
        updateUser({
            actionType,
            objectId: user.objectId,
            brandRoles: uniqBrandRole([...user.brandRoles, ...newUserBrandRoles]),
            portalRoles: user.portalRoles,
            roles: user.roles,
            tmsAccounts: user.tmsAccounts,
            requestedTmsAccountNumbers: user.requestedTmsAccountNumbers,
        })
    }
    const removeUserBrand = (index: number, actionType: 'brand' | 'tms') => {
        const updatedGroupedUserBrandRoles = groupedUserBrandRoles.filter((_, i) => i !== index)
        const updatedUserBrandRoles = ungroupedBrandRoles(updatedGroupedUserBrandRoles)
        updateUser({
            actionType,
            objectId: user.objectId,
            brandRoles: updatedUserBrandRoles,
            portalRoles: user.portalRoles,
            roles: user.roles,
            tmsAccounts: user.tmsAccounts,
            requestedTmsAccountNumbers: user.requestedTmsAccountNumbers,
        })
    }

    return {
        updateUserBrands,
        removeUserBrand,
        addUserBrand,
    }
}
