import hotkeys from 'hotkeys-js'
import { DependencyList, useEffect, useCallback } from 'react'

export const useHotkey = (
    key: string | false,
    callback: (event: KeyboardEvent) => void,
    deps: DependencyList = [],
) => {
    const memoisedCallback = useCallback(callback, deps)

    useEffect(() => {
        if (!key) {
            return
        }

        hotkeys(key, memoisedCallback)

        return () => hotkeys.unbind(key, memoisedCallback)
    }, [key, memoisedCallback])
}
