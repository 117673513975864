import styled from 'styled-components'
import React, { FC } from 'react'
import { H1 } from '../../../components/base/Typography'
import { Box } from '../../../components/base/Box'
import { greyDark2, greyLine2, titleFontFamily } from '../../../styles/theme'
import { User } from '../../../common/user/User'
import { CountryShort } from '../../../common/user/CountryShort'
import { UserTmsAccountsVerificationStatus } from './components/UserTmsAccountsVerificationStatus'

interface UserInfoProps {
    user: User
}

export const UserInfo: FC<UserInfoProps> = ({ user }) => (
    <>
        <Box marginBottom={32} column>
            <InfoText>{user.jobTitle}</InfoText>
            <UserName>{`${user.givenName} ${user.surname}`}</UserName>
            <InfoText>{user.emails.join(', ')}</InfoText>
        </Box>

        {user.telephoneNumber && (
            <Box marginBottom={16} column>
                <span>Office phone</span>
                <InfoText>{user.telephoneNumber}</InfoText>
            </Box>
        )}

        {user.mobile && (
            <Box marginBottom={32} column>
                <span>Mobile phone</span>
                <InfoText>{user.mobile}</InfoText>
            </Box>
        )}

        <Box marginBottom={32} column>
            <UserCompany>{user.companyName}</UserCompany>
            <InfoText>{user.streetAddress}</InfoText>
            <InfoText>
                {user.city}
                {user.postalCode && `, ${user.postalCode}`}
            </InfoText>
            <InfoText>{CountryShort[user.country as keyof typeof CountryShort]}</InfoText>
        </Box>

        <UserTmsAccountsVerificationStatus
            requestedTmsAccountNumbers={user.requestedTmsAccountNumbers}
        />
    </>
)

const UserName = styled(H1)`
    font-family: ${titleFontFamily};
    line-height: 40px;
    color: ${greyLine2};
    margin: 0;
`

const InfoText = styled.span`
    color: ${greyDark2};
`

const UserCompany = styled(InfoText)`
    font-size: 16px;
    line-height: 20px;
`
