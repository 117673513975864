import jwt from 'jsonwebtoken'
import {
    checkGlobalAdminRole,
    idcsGroupsToBrandRoles,
    idcsGroupsToPortalRoles,
    Role,
} from '../user/Role'
import { AuthUser } from './AuthUser'

export class AuthUserExtractor {
    public static decodeToken = (token: string): { [key: string]: any } => {
        const payload = jwt.decode(token)
        if (payload && typeof payload === 'object') {
            return payload
        }
        return {}
    }

    public static extractAuthUser = (token: string, bareGroups: string[]): AuthUser => {
        const payload = AuthUserExtractor.decodeToken(token)
        const id = payload.user_id as string
        const email = payload.sub
        const roles = checkGlobalAdminRole(bareGroups) ? [Role.GlobalAdmin] : []
        const brandRoles = idcsGroupsToBrandRoles(bareGroups)
        const portalRoles = idcsGroupsToPortalRoles(bareGroups)
        const displayName = payload.user_displayname as string
        const clientAppRoles = (payload.clientAppRoles ?? []) as string[]
        return new AuthUser(id, roles, brandRoles, portalRoles, clientAppRoles, displayName, email)
    }
}
