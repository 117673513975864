import styled from 'styled-components'
import React, { FC, ReactNode } from 'react'
import { Box, BoxProps } from '../base/Box'
import { greyDark, greyLight2, white } from '../../styles/theme'
import { Icon } from '../icon/Icon'

interface ChipProps extends BoxProps {
    label: ReactNode
    active?: boolean
    onDelete?: () => void
}

export const Chip: FC<ChipProps> = ({ label, onDelete, ...marginProps }) => (
    <ChipBox paddingLeft={16} paddingRight={4} row {...marginProps}>
        {label}
        {onDelete && (
            <ChipIconBox onClick={onDelete} padding={4} paddingLeft={8}>
                <Icon size={20} name="x" />
            </ChipIconBox>
        )}
    </ChipBox>
)

const ChipIconBox = styled(Box)`
    cursor: pointer;
`

const ChipBox = styled(Box) <{ active?: boolean }>`
    display: inline-block;
    padding: 4px 12px;
    border-radius: 16px;
    border: 1px solid ${props => (props.active ? greyDark : greyLight2)};
    background: ${props => (props.active ? greyDark : 'transparent')};
    color: ${props => (props.active ? white : greyLight2)};
    font-size: 12px;
    font-weight: 500;
`
