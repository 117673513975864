import React, { FC } from 'react'
import styled from 'styled-components'
import { Stylable } from '../types/Stylable'
import { icons } from './icons'
import { Svg } from './Svg'

export type IconName = keyof typeof icons

interface IconProps extends Stylable {
    name: IconName
    color?: string
    size?: number
}

export const Icon: FC<IconProps> = ({ name, className, color, size = 24 }) => (
    <Container size={size} content={icons[name]} className={className} style={{ color }} />
)

const Container = styled(Svg)<{ size: number }>`
    width: ${p => p.size}px;
    height: ${p => p.size}px;

    svg * {
        fill: currentColor;
    }
`
