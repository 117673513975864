import { AuthData } from '../../services/auth/AuthService'
import { AuthUser } from '../../common/auth/AuthUser'

export interface AuthState {
    authData?: AuthData
    authUser?: AuthUser
    loginFailed: boolean
    loginInProgress: boolean
}

export interface AuthStateAware {
    auth: AuthState
}

export const initialAuthState: AuthState = {
    loginFailed: false,
    loginInProgress: false,
}
