import React, { FC } from 'react'
import { Modal } from '../../components/modal/Modal'
import { ActionModalContent } from '../../components/modal/ActionModalContent'

interface CannotDeleteUserModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

export const CannotDeleteUserModal: FC<CannotDeleteUserModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
}) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ActionModalContent
            title="Deletion warning"
            description="Cannot delete user with active permissions or Global Admin. Please remove all permissions and try again."
            buttonLabel="Close"
            icon="warning"
            onConfirm={onConfirm}
        />
    </Modal>
)
