import React, { FC } from 'react'
import styled from 'styled-components'
import { textColor } from '../../styles/theme'
import { Icon, IconName } from '../icon/Icon'

interface IconButtonProps {
    icon: IconName
    title?: string
    onClick: () => void
    disabled?: boolean
    small?: boolean
    color?: string
}

export const IconButton: FC<IconButtonProps> = ({
    icon,
    title,
    onClick,
    disabled,
    small,
    color = textColor,
}) => (
    <Btn onClick={onClick} title={title} disabled={disabled} small={small}>
        <Icon name={icon} color={color} />
    </Btn>
)

const Btn = styled.button<{ small?: boolean }>`
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    &:focus {
        outline: none;
    }
    width: ${p => (p.small ? 32 : 38)}px;
    height: ${p => (p.small ? 32 : 38)}px;
    display: flex;
    justify-content: center;
    align-items: center;
`
