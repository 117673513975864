import React, { FC, useState } from 'react'

import styled from 'styled-components'
import { Box, BoxProps } from '../../../../components/base/Box'
import { Button } from '../../../../components/button/Button'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { LinkButton } from '../../../../components/button/LinkButton'
import { Checkbox } from '../../../../components/form/Checkbox'
import { Field } from '../../../../components/form/Field'
import { Input } from '../../../../components/form/Input'
import { Icon } from '../../../../components/icon/Icon'
import { Spinner } from '../../../../components/spinner/Spinner'
import { brandColor, warningColor } from '../../../../styles/theme'
import {
    TmsAccountOption,
    TmsAccountOptionStatus,
    TmsAccountWithName,
    toTmsAccountNumbers,
} from '../../../../common/user/TmsAccountWithName'
import {
    Account,
    AccountSpan,
    HiddableContainer,
    IndentedAccount,
    NotFoundAccountSpan,
    SpinnerContainer,
} from './UserBrandFormAccountElements'

interface UserFormTmsAccountsOptionsProps extends BoxProps {
    accountsOptions: TmsAccountOption[]
    selectedAccounts: TmsAccountOption[]
    newAccountNumber: string
    tmsVerificationInProgress: boolean
    onSelect: (accounts: TmsAccountOption[]) => void
    onChange: (value: string) => void
    onVerify: () => void
    onAddAndVerify: (value: string) => void
}

const subTitle = (account: TmsAccountWithName) => `${account.abbreviation ?? 'TMS'} ${account.number} - ${account.name}`

const previewID = (account: TmsAccountWithName) => {
    const accountType = account.accountType as string | null
    if (accountType && (accountType.toLowerCase() === 'machines' || accountType.toLowerCase() === 'aftermarket')) {
        const capitalize = (val: string) => val.charAt(0).toUpperCase() + val.slice(1)
        return `(ID: ${account.id} - ${capitalize(accountType.toLowerCase())})`
    }
    return `(ID: ${account.id})`
}

export const UserFormTmsAccountsOptions: FC<UserFormTmsAccountsOptionsProps> = ({
    accountsOptions,
    selectedAccounts,
    newAccountNumber,
    tmsVerificationInProgress,
    onSelect,
    onChange,
    onVerify,
    onAddAndVerify,
}) => {
    const [tooManyAccountsError, setTooManyAccountsError] = useState(false)

    const shouldShowVerificationAction = accountsOptions.some(
        a => a.status === TmsAccountOptionStatus.Pending,
    )

    const isAccountSelected = (account: TmsAccountOption): boolean =>
        selectedAccounts.some(a => a.number === account.number && a.name === account.name)

    const renderTmsAccountOption = (account: TmsAccountOption, index: number) => {
        switch (account.status) {
            case TmsAccountOptionStatus.Verified:
                return (
                    <Account key={index}>
                        <Checkbox
                            title={subTitle(account)}
                            subTitle={previewID(account)}
                            checked={isAccountSelected(account)}
                            onChange={value => onSelectAccount(account, value)}
                        />
                    </Account>
                )
            case TmsAccountOptionStatus.NotFound:
                return (
                    <IndentedAccount key={index}>
                        <NotFoundAccountSpan>{`${account.number}\u00A0\u00A0\u00A0Account number doesn’t exist`}</NotFoundAccountSpan>
                    </IndentedAccount>
                )
            default:
                return (
                    <Account key={index}>
                        <AccountSpan>{account.number}</AccountSpan>
                    </Account>
                )
        }
    }

    const onSelectAccount = (a: TmsAccountOption, v: boolean) => {
        if (v) {
            onSelect([...selectedAccounts, a])
        } else {
            onSelect(selectedAccounts.filter(acc => acc.number !== a.number && acc.name !== a.name))
        }
    }

    const allAccounts = accountsOptions.filter(a => a.status === TmsAccountOptionStatus.Verified)
    const allSelected = selectedAccounts.length === allAccounts.length
    const toggleAll = (v: boolean) => {
        if (v) {
            onSelect(allAccounts)
        } else {
            onSelect([])
        }
    }

    const addAndVerify = () => {
        if (toTmsAccountNumbers(newAccountNumber).length > 1000) {
            setTooManyAccountsError(true)
        } else {
            setTooManyAccountsError(false)
            onAddAndVerify(newAccountNumber)
        }
    }

    return (
        <Box relative>
            <SpinnerContainer show={tmsVerificationInProgress}>
                <Spinner />
            </SpinnerContainer>
            <HiddableContainer show={!tmsVerificationInProgress}>
                {tooManyAccountsError && <Error>Maximum 1000 accounts allowed at once</Error>}
                <Box>{accountsOptions.map(renderTmsAccountOption)}</Box>
                {shouldShowVerificationAction && !tmsVerificationInProgress && (
                    <Box justify="space-between" row>
                        <ButtonGroup paddingTop={24} flex={1}>
                            <Button variant="outlined" onClick={onVerify} small>
                                Verification
                            </Button>
                        </ButtonGroup>
                        <Box paddingTop={24} flex={1} row>
                            <Box alignSelf="center">
                                <Icon name="exclamationTriangle" color={warningColor} />
                            </Box>
                            <Box paddingLeft={16} flex={1}>
                                <Warning bold>Pending Customer TMS Accounts Numbers</Warning>
                                <Warning>
                                    Please, verify pending TMS Accounts Numbers requested by user
                                    during self-registration.
                                </Warning>
                            </Box>
                        </Box>
                    </Box>
                )}
                {!tmsVerificationInProgress && !!allAccounts.length && (
                    <Checkbox
                        title="Select / Unselect All"
                        checked={allSelected}
                        onChange={toggleAll}
                    />
                )}
                <Box paddingTop={shouldShowVerificationAction ? 16 : 32} row>
                    <Box>
                        <Field label="Verify ERP Account Number">
                            <Input
                                placeholder="Account Number"
                                value={newAccountNumber}
                                onTextChange={value => onChange(value)}
                                small
                                smallText
                            />
                        </Field>
                    </Box>
                    <Box paddingLeft={16}>
                        <LinkButton onClick={addAndVerify}>Verify</LinkButton>
                    </Box>
                </Box>
            </HiddableContainer>
        </Box>
    )
}

const Error = styled(Box)`
    color: ${brandColor};
`

const Warning = styled(Box)<{ bold?: boolean }>`
    color: ${warningColor};
    font-size: 12px;
    font-weight: ${p => (p.bold ? '700' : '400')};
`
