import React, { FC, CSSProperties } from 'react'
import styled from 'styled-components'
import { Stylable } from '../types/Stylable'

interface SvgProps extends Stylable {
    content: string
    style?: CSSProperties
}

export const Svg: FC<SvgProps> = ({ content, className, style }) => (
    <Container dangerouslySetInnerHTML={{ __html: content }} className={className} style={style} />
)

const Container = styled.span`
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
    }
`
