import React, { FC } from 'react'
import { EnumValues } from 'enum-values'
import { ListDropdown } from '../../components/dropdown/ListDropdown'
import { UserListFilter, userListFilterNames } from '../../common/user/UserRequest'

export interface UserFiltersProps {
    activateFilter: (f: UserListFilter) => void
}

export const UserFilters: FC<UserFiltersProps> = ({ activateFilter }) => (
    <ListDropdown
        icon="filter-list"
        marginLeft={8}
        items={EnumValues.getValues<UserListFilter>(UserListFilter).filter(
            f => f !== UserListFilter.PENDING_REQUESTS,
        )}
        formatter={f => userListFilterNames[f]}
        onActivate={activateFilter}
    />
)
