import React, { FC } from 'react'
import styled from 'styled-components'
import { overlayColor } from '../../styles/theme'
import { Spinner } from './Spinner'

export const FullscreenSpinner: FC = () => (
    <SpinnerWrapper>
        <Spinner />
    </SpinnerWrapper>
)

const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${overlayColor};
`
