import styled from 'styled-components'
import { titleFontFamily } from '../../styles/theme'

export const H1 = styled.h1`
    font-weight: 300;
    font-size: 32px;
    line-height: 1.5;
    margin: 36px 0 24px;
`

export const H2 = styled.h2`
    font-weight: 300;
    font-size: 26px;
    line-height: 1.33;
    margin: 0;
`
export const H3 = styled.h3`
    font-family: ${titleFontFamily};
    font-weight: 300;
    font-size: 24px;
    line-height: 1.33;
    margin: 0;
`
