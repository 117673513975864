import { EnumValues } from 'enum-values'

export enum Portal {
    Proemion = 'PROEMION',
    Orbcomm = 'ORBCOMM',
}

export const portalNames: Record<Portal, string> = {
    [Portal.Proemion]: 'Proemion',
    [Portal.Orbcomm]: 'Orbcomm',
}

export const portalName = (r: Portal): string => portalNames[r]

export const allPortals: Portal[] = EnumValues.getValues<Portal>(Portal).sort((a, b) =>
    portalNames[a].localeCompare(portalNames[b]),
)

export const isValidPortal = (portal: string): portal is Portal =>
    EnumValues.getValues(Portal).includes(portal)
