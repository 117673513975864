import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { noop } from 'lodash'
import logger from 'redux-logger'
import { registerAuthRequestInterceptor } from '../services/auth/authRequestInterceptor'
import { BaseRestService } from '../services/base/BaseRestService'
import { authService } from '../services/auth/AuthService'
import { registerBaseUrlRequestInterceptor } from '../services/base/baseUrlInterceptor'
import { appConfig } from '../config/appConfig'
import { rootReducer } from './rootReducer'
import { rootEpic } from './rootEpics'
import { logout, setToken } from './auth/authActions'

const epicMiddleware = createEpicMiddleware()

const composeEnhancers = composeWithDevTools({ name: 'App' })

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware, logger)),
)

epicMiddleware.run((rootEpic as unknown) as any)

// eslint-disable-next-line @typescript-eslint/no-floating-promises
authService
    .process()
    .then(token => {
        store.dispatch(setToken(token))
    })
    .catch(noop)

if (
    // Avoid re-registering interceptors during app hot-reloading
    BaseRestService.requestInterceptors.length === 0 &&
    BaseRestService.responseInterceptors.length === 0
) {
    registerBaseUrlRequestInterceptor(appConfig.apiUrl)
    registerAuthRequestInterceptor(
        () => store.getState().auth.authData,
        data => store.dispatch(setToken(data)),
        e => {
            if ((e as any).status === 401) {
                store.dispatch(
                    logout({ purge: false, idToken: store.getState().auth.authData?.idToken }),
                )
            }
            // eslint-disable-next-line no-console
            console.error(e)
        },
    )
}
