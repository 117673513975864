import styled, { css } from 'styled-components'
import { greyBackground, greyLight, greyLine, greyLine2, transitionFast } from '../../styles/theme'

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`

const baseStyles = css`
    padding: 16px;
    font-size: 13px;
    border-bottom: 1px solid ${greyLine};
`

export const Tbody = styled.tbody``
export const Thead = styled.thead``
export const Tr = styled.tr<{ pointer?: boolean; disabled?: boolean; header?: boolean }>`
    ${p =>
        p.pointer &&
        !p.disabled &&
        css`
            cursor: pointer;

            &:focus {
                outline: 1px solid red;
            }
        `}
    ${p =>
        p.disabled &&
        css`
            color: ${greyLight};
        `}
`

export const Th = styled.th`
    ${baseStyles}
    color: ${greyLine2};
    font-weight: normal;
    text-align: left;
`

export const Td = styled.td`
    background-color: white;
    transition: ${transitionFast} background-color;
    text-align: ${p => p.align ?? 'left'};
    line-height: 16px;
    color: ${greyLine2};
    ${baseStyles}

    ${Tr}:hover & {
        background: ${greyBackground};
    }
`

export const ShowOnTrHover = styled.div`
    opacity: 0;
    transition: ${transitionFast} opacity;

    ${Tr}:hover & {
        opacity: 1;
    }
`
