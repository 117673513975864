import React, { FC } from 'react'
import { ListDropdown } from '../../components/dropdown/ListDropdown'

export interface UserFiltersProps {
    exportUsers: () => void
    templateUpload: () => void
}

const actions: Record<string, string> = {
    export: 'Export to excel',
    template: 'Batch edit users',
}

export const MoreActions: FC<UserFiltersProps> = ({ exportUsers, templateUpload }) => (
    <ListDropdown
        icon="more"
        items={Object.keys(actions)}
        formatter={f => actions[f]}
        onActivate={(item: string) => {
            if (item === 'export') {
                exportUsers()
            } else {
                templateUpload()
            }
        }}
    />
)
