import styled from 'styled-components'
import { greyDark2, greyLight, redTextColor, warningColor } from '../../../../styles/theme'

export const HiddableContainer = styled.div<{ show?: boolean }>`
    visibility: ${p => (p.show ? 'visible' : 'hidden')};
`

export const SpinnerContainer = styled.div<{ show?: boolean }>`
    display: ${p => (p.show ? 'block' : 'none')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`

export const IndentedAccount = styled.div`
    padding: 4px 0 4px 20px;
    font-size: 14px;
`

export const Account = styled.div`
    font-size: 14px;
    color: ${greyDark2};
`

export const AccountSpan = styled.span`
    padding: 4px 0;
    color: ${greyDark2};
`

export const AccountIdSpan = styled.span`
    padding: 4px 0 4px 8px;
    color: ${greyLight};
    font-size: 12px;
`

export const NotFoundAccountSpan = styled.span`
    padding: 4px 0;
    color: ${warningColor};
`

export const ErrorSpan = styled.span`
    padding: 4px 0;
    color: ${redTextColor};
`
