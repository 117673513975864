import { DateTime } from 'luxon'
import { Log, OidcClient, OidcClientSettings, SigninResponse, UserManager } from 'oidc-client'
import uuidv1 from 'uuid/v1'
import { appConfig, AppConfig } from '../../config/appConfig'

export interface AuthData {
    userId: string
    idToken: string
    accessToken: string
    accessTokenExpires: number
    groups: string[]
}

class AuthService {
    private readonly editProfileUrl: string

    private readonly signInClient: UserManager

    private readonly settings: OidcClientSettings

    constructor(appConfig: AppConfig) {
        Log.logger = console
        Log.level = Log.INFO

        this.editProfileUrl = appConfig.editProfileUrl
        this.settings = {
            client_id: appConfig.clientId,
            redirect_uri: appConfig.redirectUri,
            authority: appConfig.idcsHost,
            response_type: 'id_token token',
            scope: 'openid groups',
            loadUserInfo: true,
        }
        this.signInClient = new UserManager(this.settings)
    }

    public process = (): Promise<Partial<AuthData>> => {
        if (window.location.href.includes('#')) {
            return (
                this.signInClient
                    .processSigninResponse()
                    .then(response => {
                        if (response.state.href) {
                            window.history.replaceState(null, '', response.state.href)
                        } else {
                            window.history.replaceState(null, '', ' ')
                        }
                        console.log(response)
                        return {
                            userId: response.user_id,
                            idToken: response.id_token,
                            accessToken: response.access_token,
                            accessTokenExpires: this.expiresInToTimestamp(response.expires_in ?? 0),
                            groups: this.resolveGroups(response),
                        }
                    })
                    // TODO: RFIPI-89 - figure out if its possible to catch AUTH-3004 or AUTH-3005 (expired/change password)
                    .catch(err => Promise.reject(err))
            )
        } else {
            return this.signInRequest(this.signInClient)
        }
    }

    public editProfile = () => window.location.replace(this.editProfileUrl)

    public getNewToken = (): Promise<Partial<AuthData>> =>
        this.signInClient.signinSilent().then(response => ({
            idToken: response.id_token,
            accessToken: response.access_token,
            accessTokenExpires: this.expiresInToTimestamp(response.expires_in || 0),
        }))

    public logout = (idToken?: string) =>
        this.signInClient
            .createSignoutRequest({ state: { v: uuidv1() }, id_token_hint: idToken })
            .then(req => {
                window.location.replace(req.url)
            })

    private signInRequest = (client: OidcClient) =>
        client
            .createSigninRequest({
                state: {
                    v: uuidv1(),
                    href: `${window.location.pathname}${window.location.search}`,
                },
            })
            .then(req => {
                window.location.replace(req.url)
                return {}
            })

    private expiresInToTimestamp = (seconds: number) =>
        DateTime.local()
            .plus({ seconds })
            .toMillis()

    private resolveGroups = (response: SigninResponse) => {
        if (response.profile.groups) {
            return response.profile.groups.map((g: any) => g.name)
        }

        return []
    }
}

export const authService = new AuthService(appConfig)
