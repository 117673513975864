import { EnumValues } from 'enum-values'
import { some } from 'lodash'
import { MainBrand } from '../user/Brand'
import { BrandRole } from '../user/BrandRole'
import { Portal } from '../user/Portal'
import { PortalRole } from '../user/PortalRole'
import { Role } from '../user/Role'
import { BrandRoleAssignment, PortalRoleAssignment } from '../user/User'

export class AuthUser {
    private roles: Role[]

    private brandRoles: BrandRoleAssignment[]

    private portalRoles: PortalRoleAssignment[]

    public readonly id: string

    public readonly name: string

    public readonly email: string

    public readonly clientAppRoles: string[]

    constructor(
        id: string,
        roles: Role[],
        brandRoles: BrandRoleAssignment[],
        portalRoles: PortalRoleAssignment[],
        clientAppRoles: string[],
        name: string,
        email: string,
    ) {
        this.id = id
        this.roles = roles
        this.brandRoles = brandRoles
        this.portalRoles = portalRoles
        this.clientAppRoles = clientAppRoles
        this.name = name
        this.email = email
    }

    public isGlobalAdmin = () => some(this.roles, a => a === Role.GlobalAdmin)

    public isAdmin = () =>
        this.getAdminBrands().length > 0 ||
        this.getAdminPortals().length > 0 ||
        this.isGlobalAdmin()

    public getAdminBrands = () =>
        this.brandRoles.filter(a => a.role === BrandRole.BrandAdmin).map(a => a.brand.mainBrand)

    public getAdminPortals = () =>
        this.portalRoles.filter(a => a.role === PortalRole.PortalAdmin).map(a => a.portal)

    public getAvailableBrands = (): MainBrand[] => {
        if (this.isGlobalAdmin()) {
            return EnumValues.getValues(MainBrand)
        }
        return this.getAdminBrands()
    }

    public getAvailablePortals = (): Portal[] => {
        if (this.isGlobalAdmin()) {
            return EnumValues.getValues(Portal)
        }
        return this.getAdminPortals()
    }
}
