import { EnumValues } from 'enum-values'
import { hasSubBrand, MainBrand, SubBrand } from './Brand'

export enum BrandRole {
    BrandAdmin = 'ADMIN',
    AftermarketMembers = 'AFTERMARKET-MEMBERS',
    MarketingMembers = 'MARKETING-MEMBERS',
    SalesMembers = 'SALES-MEMBERS',
    DealerMembers = 'DEALER-MEMBERS',
    ShipmentsLevelAccess = 'SHIPMENTS-ACCESS',
    InvoicesLevelAccess = 'INVOICES-ACCESS',
    OrdersLevelAccess = 'ORDERS-ACCESS',
    InternalLevelAccess = 'INTERNAL-LEVEL-ACCESS',
    MachineAlertsAccess = 'MACHINE-ALERTS-ACCESS',
    eVentoryDataWriteAccess = 'EVENTORY-DATA-ACCESS',
    eVentoryDataReadAccess = 'EVENTORY-DATA-READ-ACCESS',
    BacklogParts = 'BACKLOG-PARTS-ACCESS',
}

export const BrandRoleDocuments = [
    BrandRole.AftermarketMembers,
    BrandRole.InternalLevelAccess,
    BrandRole.MarketingMembers,
    BrandRole.SalesMembers,
    BrandRole.DealerMembers,
]

export const BrandRoleBusiness = [
    BrandRole.eVentoryDataWriteAccess,
    BrandRole.eVentoryDataReadAccess,
    BrandRole.ShipmentsLevelAccess,
    BrandRole.InvoicesLevelAccess,
    BrandRole.OrdersLevelAccess,
    BrandRole.MachineAlertsAccess,
    BrandRole.BacklogParts,
]

const brandRoleNames: Record<BrandRole, string> = {
    [BrandRole.BrandAdmin]: 'Brand admin',
    [BrandRole.AftermarketMembers]: 'Aftermarket',
    [BrandRole.MarketingMembers]: 'Marketing',
    [BrandRole.SalesMembers]: 'Sales',
    [BrandRole.DealerMembers]: 'Dealer',
    [BrandRole.ShipmentsLevelAccess]: 'Shipments',
    [BrandRole.InvoicesLevelAccess]: 'Invoices',
    [BrandRole.OrdersLevelAccess]: 'Orders',
    [BrandRole.InternalLevelAccess]: 'Internal Confidential',
    [BrandRole.MachineAlertsAccess]: 'Machine Alerts',
    [BrandRole.eVentoryDataWriteAccess]: 'eVentory -write-',
    [BrandRole.eVentoryDataReadAccess]: 'eVentory -read-',
    [BrandRole.BacklogParts]: 'Backlog Parts',
}

export const brandRoleName = (r: BrandRole) => brandRoleNames[r]

export const getBrandRoles = (mainBrand: MainBrand, subBrand?: SubBrand): BrandRole[] => {
    const brandHasSubBrands = hasSubBrand(mainBrand)
    if (brandHasSubBrands && !subBrand) {
        return [BrandRole.BrandAdmin]
    }
    if (brandHasSubBrands && !!subBrand) {
        return EnumValues.getValues(BrandRole).filter(
            b => b !== BrandRole.BrandAdmin,
        ) as BrandRole[]
    }

    return EnumValues.getValues(BrandRole)
}
