import arrowLeft from './assets/arrow-left.svg'
import arrowRight from './assets/arrow-right.svg'
import check from './assets/check.svg'
import deleteRound from './assets/delete-round.svg'
import editProfile from './assets/edit-profile.svg'
import edit from './assets/edit.svg'
import exclamationRound from './assets/exclamation-round.svg'
import exclamationTriangle from './assets/exclamation-triangle.svg'
import filterList from './assets/filter-list.svg'
import info from './assets/info.svg'
import more from './assets/more.svg'
import plus from './assets/plus.svg'
import signOut from './assets/sign-out.svg'
import user from './assets/user.svg'
import warning from './assets/warning.svg'
import x from './assets/x.svg'

export const icons = {
    plus,
    check,
    x,
    edit,
    user,
    warning,
    'arrow-left': arrowLeft,
    'arrow-right': arrowRight,
    'sign-out': signOut,
    'edit-profile': editProfile,
    'filter-list': filterList,
    more,
    'delete-round': deleteRound,
    info,
    exclamationTriangle,
    exclamationRound,
}
