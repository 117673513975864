import React, { FC, useRef } from 'react'
import styled from 'styled-components'
import { uniqueId, noop } from 'lodash'

interface SwitchProps {
    checked: boolean

    onChange?: (v: boolean) => void
}

export const Switch: FC<SwitchProps> = ({ children, checked, onChange }) => {
    const value = useRef(uniqueId('checkbox'))
    return (
        <SwitchContainer>
            <input
                id={value.current}
                onChange={v => (onChange ?? noop)(v.target.checked)}
                checked={checked}
                type="checkbox"
            />
            <label htmlFor={value.current}>{children}</label>
        </SwitchContainer>
    )
}

const SwitchContainer = styled.div`
    > input {
        display: none;
    }

    > label {
        position: relative;
        padding-left: 42px;
        line-height: 24px;
        cursor: pointer;

        &::before,
        &::after {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: 50% 50%;
            content: '';
        }
        &::before {
            width: 32px;
            height: 16px;
            border-radius: 8px;
            background-color: #625f5d;
            transform: translate(0, -50%);
        }
        &::after {
            transition: transform 200ms ease;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            background-color: white;
            transform: translate(0, -50%);
            left: 2px;
        }
    }

    > input:checked + label::before {
        background-color: #0f9348;
    }

    > input:checked + label::after {
        transform: translate(16px, -50%);
    }
`
