import styled from 'styled-components'
import React, { FC } from 'react'
import { Icon } from '../../../../components/icon/Icon'
import { ShowOnTrHover, Td } from '../../../../components/table/Table'
import { Box } from '../../../../components/base/Box'

interface UserRowEditHintProps {
    canEdit: boolean
    variant?: 'brand' | 'portal' | 'application'
}

export const UserRowEditHint: FC<UserRowEditHintProps> = ({
    canEdit,
    variant,
}: UserRowEditHintProps) => (
    <EditTd align="right" variant={variant}>
        {canEdit && (
            <ShowOnTrHover>
                <Box align="center">
                    <Icon name="edit" />
                    <EditLabel>Edit</EditLabel>
                </Box>
            </ShowOnTrHover>
        )}
    </EditTd>
)

const EditTd = styled(Td)`
    vertical-align: ${props => (props.variant === 'application' ? 'middle;' : 'top;')};
    ${props => (props.variant === 'brand' ? 'padding-top: 32px;' : '')}
    ${props => (props.variant === 'application' ? 'padding: 6px 16px;' : '')}
`

const EditLabel = styled.span`
    vertical-align: middle;
    padding: 0 8px;
    font-size: 13px;
`
