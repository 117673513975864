import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { load, update, getActiveBrandRoles } from './brandsActions'
import { BrandsStateAware } from './brandsState'

export const useBrands = () => {
    const actions = useActions({
        loadBrands: load.started,
    })
    const brandsState = useSelector((state: BrandsStateAware) => state.brands)

    return {
        ...brandsState,
        ...actions,
    }
}

export const useBrandActions = () => {
    const actions = useActions({
        updateBrand: update.started,
    })
    const brandsState = useSelector((state: BrandsStateAware) => state.brands)

    return {
        ...brandsState,
        ...actions,
    }
}

export const useBrandsActiveRoles = () => {
    const actions = useActions({
        loadBrandsActiveRoles: getActiveBrandRoles.started,
    })
    const brandsState = useSelector((state: BrandsStateAware) => state.brands)

    return {
        ...brandsState,
        ...actions,
    }
}
