import React, { FC } from 'react'
import styled from 'styled-components'
import { greyDark2, warningColor } from '../../styles/theme'
import { Stylable } from '../types/Stylable'

interface FieldProps extends Stylable {
    label: string
    error?: string
    as?: string
}

export const Field: FC<FieldProps> = ({ label, error, children, className, as }) => (
    <Container className={className} as={as as any}>
        <Label>{label}</Label>
        {children}
        {error && <Error>{error}</Error>}
    </Container>
)

const Container = styled.label`
    display: block;
    padding-bottom: 16px;
`

const Label = styled.div`
    padding-bottom: 8px;
    color: ${greyDark2};
    font-size: 13px;
`

const Error = styled.div`
    padding-top: 8px;
    color: ${warningColor};
    font-size: 13px;
`
