import React, { FC } from 'react'
import styled from 'styled-components'
import { Th, Tr } from '../../../components/table/Table'

export const ApplicationRowHeader: FC = () => (
    <Tr header>
        <TableTh style={{ width: 220 }}>IDCS group name</TableTh>
        <TableTh>Dispaly Name</TableTh>
        <TableTh>URL Address</TableTh>
        <TableTh>Image URL Address</TableTh>
        <TableTh>Active</TableTh>
        <Th />
    </Tr>
)

const TableTh = styled(Th)`
    white-space: nowrap;
`
