import { DateTime } from 'luxon'
import { EMPTY, Observable, of, from } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { isEqual } from 'lodash'
import { BaseRestService, RequestInterceptorParam } from '../base/BaseRestService'
import { isPublicPath } from '../../config/appConfig'
import { authService, AuthData } from './AuthService'

const isExpired = (expiresIn: number) =>
    DateTime.local()
        .minus({ minutes: 2 })
        .toMillis() > (expiresIn || 0)

const authRequestInterceptor = (
    tokenProvider: () => AuthData | undefined,
    onNewTokenData: (data: Partial<AuthData>) => void,
    onError: (e: Error) => void,
) => (param: RequestInterceptorParam): Observable<RequestInterceptorParam> => {
    if (isPublicPath(param[0])) {
        return of(param)
    }
    const token = tokenProvider()
    if (!token) {
        return of(param)
    }

    return from(
        Promise.resolve(token)
            .then(currentToken => {
                if (isExpired(currentToken.accessTokenExpires)) {
                    return authService
                        .getNewToken()
                        .then(newToken => ({ ...newToken, ...currentToken }))
                }
                return currentToken
            })
            .then(currentToken => {
                if (!isEqual(currentToken, token)) {
                    onNewTokenData(currentToken)
                }
                return currentToken
            }),
    ).pipe(
        switchMap(data => withAuthorizationHeader(param, data)),
        catchError(e => {
            onError(e)
            return EMPTY
        }),
    )
}

const withAuthorizationHeader = ([url, init = {}]: RequestInterceptorParam, authData: AuthData) => {
    const headers: Record<string, any> = {
        ...init.headers,
        Authorization: `Bearer ${authData.accessToken}`,
    }
    if (process.env.NODE_ENV !== 'production') {
        headers['user-id'] = authData.userId ?? 'd27aee2f30d24facb08f0d6dd2ee65c0'
    }

    const param = [url, { ...init, headers }] as RequestInterceptorParam

    return of(param)
}

export const registerAuthRequestInterceptor = (
    tokenProvider: () => AuthData | undefined,
    onNewTokenData: (data: Partial<AuthData>) => void,
    onError: (e: Error) => void,
) => {
    BaseRestService.requestInterceptors.push(
        authRequestInterceptor(tokenProvider, onNewTokenData, onError),
    )
}
