import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '../base/Box'
import { greyLight, greyLine } from '../../styles/theme'
import logo from './assets/logo.svg'

export const Logo: FC = () => (
    <Box row>
        <SvgWrapper dangerouslySetInnerHTML={{ __html: logo }} />
        <Title>{'Users\nManagement'}</Title>
    </Box>
)

const Title = styled.h1`
    margin: 0;
    color: ${greyLight};
    font-weight: normal;
    font-size: 12px;
    line-height: 1.33;
    text-transform: uppercase;
    white-space: pre-wrap;
    border-left: 1px solid ${greyLine};
    padding-left: 16px;
    margin-left: 32px;
`

const SvgWrapper = styled.div`
    width: 81px;
    height: 29px;

    svg {
        display: block;
    }
`
