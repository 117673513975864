import { lighten } from 'polished'
import React, { ButtonHTMLAttributes, FC } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import {
    borderRadius,
    brandColor,
    fontFamily,
    greyLine,
    textColor,
    transitionFast,
    white,
} from '../../styles/theme'
import { Icon, IconName } from '../icon/Icon'

type ButtonStyle = 'primary' | 'secondary' | 'outlined' | 'flat'

interface ButtonProps {
    icon?: IconName
    variant?: ButtonStyle
    type?: ButtonHTMLAttributes<Element>['type']
    small?: boolean
    onClick?: () => void
    disabled?: boolean
}

export const Button: FC<ButtonProps> = ({
    onClick,
    children,
    icon,
    type = 'button',
    variant = 'outlined',
    small = false,
    disabled = false,
}) => (
    <StyledButton
        type={type}
        onClick={onClick}
        hasIcon={!!icon}
        variant={variant}
        small={small}
        disabled={disabled}
    >
        {icon && <ButtonIcon name={icon} />}
        {children}
    </StyledButton>
)

const buttonStyles: { [key: string]: FlattenSimpleInterpolation } = {
    primary: css`
        color: ${white};
        border: none;
        &:focus {
            outline: none;
        }
    `,
    secondary: css`
        color: ${textColor};
        border: none;
        &:focus {
            outline: none;
        }
    `,
    outlined: css`
        color: ${brandColor};
        border: 1px solid ${brandColor};
        &:focus {
            outline: none;
        }
    `,
    flat: css`
        border: none;
        color: ${textColor};
        &:focus {
            outline: none;
        }
    `,
}

const buttonEnabledStyles: { [key: string]: FlattenSimpleInterpolation } = {
    primary: css`
        background: ${brandColor};
        &:hover {
            background-color: ${lighten(0.1, brandColor)};
        }
    `,
    secondary: css`
        background: white;
        &:hover {
            background-color: ${lighten(0.05, greyLine)};
        }
    `,
    outlined: css`
        background: white;
        &:hover {
            background-color: ${lighten(0.55, brandColor)};
        }
    `,
    flat: css`
        background: white;
        &:hover {
            background-color: ${lighten(0.05, greyLine)};
        }
    `,
}

const buttonDisabledStyles: { [key: string]: FlattenSimpleInterpolation } = {
    primary: css`
        background: ${lighten(0.05, greyLine)};
    `,
    secondary: css`
        background: white;
    `,
    outlined: css`
        background: white;
    `,
    flat: css`
        background: white;
    `,
}

const StyledButton = styled.button<{
    hasIcon: boolean
    variant: ButtonStyle
    small?: boolean
    disabled?: boolean
}>`
    position: relative;
    ${p =>
        p.small
            ? css`
                  font-size: 13px;
                  padding: 0 16px 0 ${p.hasIcon ? '24px' : '16px'};
                  height: 32px;
              `
            : css`
                  font-size: 16px;
                  padding: 0 26px 0 ${p.hasIcon ? '48px' : '26px'};
                  height: 38px;
              `}
    font-family: ${fontFamily};
    border-radius: ${borderRadius};
    cursor: pointer;
    transition: ${transitionFast} all;
    white-space: nowrap;

    ${p => buttonStyles[p.variant]}
    ${p => (p.disabled ? buttonDisabledStyles[p.variant] : buttonEnabledStyles[p.variant])}
    
`

const ButtonIcon = styled(Icon)`
    display: block;
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
`
