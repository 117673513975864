import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { Box } from '../../components/base/Box'
import { FullscreenSpinner } from '../../components/spinner/FullscreenSpinner'
import { Table, Thead, Tbody } from '../../components/table/Table'

import { useUsers } from '../../store/users/usersHooks'
import { useApplications, useApplicationActions } from '../../store/applications/applicationsHooks'
import { ApplicationRowHeader } from './components/ApplicationRowHeader'
import { ApplicationsRow } from './components/ApplicationsRow'
import { Helmet } from 'react-helmet'

export const SettingsApplications: FC = () => {
    const { items, loadingAll, loadApplications } = useApplications()
    const { updateApplication, updateInProgress } = useApplicationActions()

    const scrollRef = React.useRef(null)

    useEffect(() => {
        loadApplications()
    }, [])

    return (
        <Wrapper ref={scrollRef}>
            <Container paddingHorizontal={32} column>
                <Box paddingVertical={8} relative>
                    <Helmet>
                        <meta name="robots" content="noindex" />
                    </Helmet>
                    <h1>Applications</h1>
                    {(updateInProgress || loadingAll) && <FullscreenSpinner />}
                    <Table>
                        <Thead>
                            <ApplicationRowHeader />
                        </Thead>
                        <Tbody>
                            {items.map((item, index) => (
                                <ApplicationsRow
                                    key={index}
                                    item={item}
                                    onSubmit={data => updateApplication(data)}
                                />
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 100%;
    overflow: auto;
`

const Container = styled(Box)`
    max-width: 1440px;
    margin: 0 auto;
`
