import { uniqBy } from 'lodash'

export interface TmsAccountWithName {
    number: string
    abbreviation?: TmsAccountAbbreviation
    name?: string
    id?: string
    accountType?: string
}

export enum TmsAccountAbbreviation {
    ONE_STREAM = 'One Stream',
    TMS = 'TMS',
    DATASUL = 'DATASUL',
    DIAPASON = 'DIAPASON',
    ENCMPX = 'ENCMPX',
    EPIC_BID = 'EPIC_BID',
    EPIC_CBI = 'EPIC_CBI',
    IFS = 'IFS',
    KINGDEE_CHA = 'KINGDEE_CHA',
    LOGIX = 'LOGIX',
    MPS = 'MPS',
    PRONTO = 'PRONTO',
    SAGE_AUS = 'SAGE_AUS',
    SAGE_FRA = 'SAGE_FRA',
    SAGE_NL = 'SAGE_NL',
    SAGE_SIN = 'SAGE_SIN',
    SAGE_TUI = 'SAGE_TUI',
    SAGE_UK = 'SAGE_UK',
    SAGE_WTN = 'SAGE_WTN',
    SYSPRO = 'SYSPRO',
    EMEAR = 'Genie Service Manager - EMEAR',
    AUS = 'Genie Service Manager - AUS',
    ORBCOMM = 'Orbcomm',
    PROEMION = 'Proemion',
    ZTR = 'ZTR',
    LEENTECH = 'Leentech',
    SFDC = 'SFDC',
    WINSPEED = 'WINSPEED',
    DUZON = 'DUZON'
}
export enum TmsAccountOptionStatus {
    Pending = 'Pending',
    Verified = 'Verified',
    NotFound = 'NotFound',
}

export interface TmsAccountOption extends TmsAccountWithName {
    status: TmsAccountOptionStatus
}

export interface TmsAccountsRequestQuery {
    accountNumber: string[]
}

export const newTmsAccountOption = (number: string): TmsAccountOption => ({
    number,
    status: TmsAccountOptionStatus.Pending,
})

export const uniqTmsAccounts = (accounts: TmsAccountWithName[]): TmsAccountWithName[] =>
    uniqBy(accounts, elem => [elem.number, elem.name, elem.accountType].join())

export const toTmsAccountNumbers = (newAccountNumber: string): string[] =>
    newAccountNumber
        ? newAccountNumber
            .split(',')
            .filter(v => v)
            .map(v => v.trim())
        : []
