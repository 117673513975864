import React, { FC } from 'react'
import styled from 'styled-components'
import { greyLine } from '../../styles/theme'
import { Box, BoxProps } from './Box'

export const Separator: FC<BoxProps> = props => (
    <Box fullWidth {...props}>
        <SeparatorLine fullWidth />
    </Box>
)

export const SeparatorLine = styled(Box)`
    border-top: 1px solid ${greyLine};
`
