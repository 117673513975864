export const brandsPermissionGuidance = [
    {
        adUser: true,
        title: 'Genie Admin',
        description:
            'Genie Admin access will have access to administer uses, visibility on all content, functions, and other various administrative permissions. This is an internal role only, before granting Brand Admin permissions please check with the Marketing Operations Teams.',
    },
    {
        adUser: false,
        title: 'Aftermarket Access',
        description:
            'Users with Aftermarket Access will be able to access aftermarket documents and associated folders in the portal.',
    },
    {
        adUser: false,
        title: 'Marketing Access',
        description:
            'Users with Marketing Access will be able to access Marketing documents and the associated folders in the portal.',
    },
    {
        adUser: false,
        title: 'Sales Access',
        description:
            'Users with Sales Access will be able to access sales documents and the associated folders in the portal.',
    },
    {
        adUser: false,
        title: 'Full Content Access',
        description:
            'Users with Full Content Access will be able to access aftermarket, marketing and sales documents and the associated folders in the portal.',
    },
    {
        adUser: false,
        title: 'Business Level Access',
        description:
            'Users with business access will have access to invoices, certificates, shipping, unit configuration and safety bulletin information, along with other business-related functions, for their assigned accounts.',
    },
    {
        adUser: true,
        title: 'Internal Confidential Level Access',
        description:
            'This is a Genie internal role only. Users with Internal Confidential Access will be able to view confidential information for internal team members such as Where Genie Wins documents, pricing information, promotional information, etc. to support them in day to day commercial activities. Do not grant this access to customers.',
    },
]
