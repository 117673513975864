import { useHistory, useParams } from 'react-router-dom'
import { RouteLink, Params, createLink } from '../Routes'

export const useRouter = () => {
    const params = useParams<Params>()
    const history = useHistory()

    const navigate = (to: RouteLink, params?: Params) => {
        const link = params ? createLink(to, params) : to
        history.push(link)
    }

    const navigateHome = () => {
        history.push('/', '')
    }

    return { navigate, navigateHome, back: history.goBack, params }
}
