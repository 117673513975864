/* eslint-disable @typescript-eslint/no-misused-promises */
import { combineEpics } from 'redux-observable'
import { Action } from 'redux'
import { Observable } from 'rxjs'
import { filter, ignoreElements, tap } from 'rxjs/operators'
import { authService } from '../../services/auth/AuthService'
import { editProfile, logout } from './authActions'

const logoutEpic = (actions$: Observable<Action>) =>
    actions$.pipe(
        filter(logout.match),

        tap(action => authService.logout(action.payload.idToken)),
        ignoreElements(),
    )

const editProfileEpic = (actions$: Observable<Action>) =>
    actions$.pipe(
        filter(editProfile.match),
        tap(() => authService.editProfile()),
        ignoreElements(),
    )

export const authEpics = combineEpics(logoutEpic, editProfileEpic)
