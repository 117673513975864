import React, { CSSProperties, ReactNode } from 'react'
import RcSelect, { ValueType } from 'react-select'
import { isEqual } from 'lodash'
import { black, brandColor, greyBackground, greyLine, textColor, white } from '../../styles/theme'

export const selectStyles = {
    control: (provided: CSSProperties, state: any) => ({
        ...provided,
        'minWidth': '250px',
        'borderColor': state.isFocused ? textColor : greyLine,
        'boxShadow': 'none',
        'fontSize': '16px',
        'minHeight': '36px',
        ':hover': {
            borderColor: state.isFocused ? textColor : greyLine,
        },
    }),

    option: (provided: CSSProperties, state: any) => ({
        ...provided,
        'background': state.isSelected ? brandColor : white,
        'color': state.isSelected ? white : black,
        'fontSize': '14px',
        ':hover': {
            background: state.isSelected ? brandColor : greyBackground,
        },
    }),
}

interface SelectProps<T extends any> {
    placeholder?: ReactNode
    value?: T
    options: T[]
    labelFormatter?: (t: T) => ReactNode
    onChange: (t?: T) => void
}

export const Select = <T extends any>({
    value,
    options,
    labelFormatter,
    onChange,
}: SelectProps<T>) => {
    const optionsOwn = options.map(o => ({
        value: o,
        label: labelFormatter ? labelFormatter(o) : o,
    }))
    const onChangeOwn = (v: ValueType<T>) => {
        if (v) {
            onChange(((v as unknown) as T).value)
        } else {
            onChange(undefined)
        }
    }
    const valueOwn = optionsOwn.find(o => isEqual(o.value, value))
    return (
        <RcSelect
            placeholder="Select brand..."
            styles={selectStyles}
            value={valueOwn}
            onChange={onChangeOwn as any}
            options={optionsOwn as any}
            isClearable
        />
    )
}
