import { DateTime } from 'luxon'
import styled from 'styled-components'
import React, { FC, useRef } from 'react'
import { Box } from '../../components/base/Box'
import { H1 } from '../../components/base/Typography'
import { Input } from '../../components/form/Input'
import { Spinner } from '../../components/spinner/Spinner'
import { Table, Tbody, Td, Th, Thead, Tr } from '../../components/table/Table'
import { useHotkey } from '../../hooks/useHotkey'
import { useRouter } from '../../hooks/useRouter'
import { RouteLink } from '../../Routes'
import { useUsers } from '../../store/users/usersHooks'
import { Pagination } from '../../components/pagination/Pagination'
import { Select } from '../../components/form/Select'
import { Chip } from '../../components/chip/Chip'
import { UserListFilter, userListFilterNames } from '../../common/user/UserRequest'
import { allBrands, Brand, brandName } from '../../common/user/Brand'
import { UserFilters } from './UserFilters'
import { MoreActions } from './MoreActions'
import { Helmet } from 'react-helmet'

export const UserListScreen: FC = () => {
    const {
        users: { items, pageSize, total },
        loadingAll,
        search,
        setSearch,
        exportUsers,
    } = useUsers()

    const { navigate } = useRouter()
    const scrollRef = React.useRef(null)

    const searchRef = useRef<HTMLInputElement>(null)
    useHotkey('/', event => {
        if (searchRef.current) {
            event.preventDefault()
            searchRef.current.focus()
            searchRef.current.select()
        }
    })
    const setBrand = (brand?: Brand) => {
        setSearch({ ...search, page: 0, brand })
    }
    const activateFilter = (f: UserListFilter) => {
        if (!search.filters.includes(f)) {
            setSearch({ ...search, page: 0, filters: [...search.filters, f] })
        }
    }
    const deactivateFilter = (f: UserListFilter) => {
        setSearch({ ...search, filters: search.filters.filter(i => i !== f) })
    }
    const exportForCurrentFilters = () => {
        exportUsers(search)
    }
    const navigateToBatchUpload = () => {
        navigate(RouteLink.BatchEditUsers)
    }

    return (
        <Wrapper ref={scrollRef}>
            <Container paddingHorizontal={32} column>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
                <Box justify="space-between" row>
                    <H1>Users</H1>
                    <Box row>
                        <Select
                            placeholder="Select brand..."
                            value={search.brand}
                            onChange={setBrand}
                            options={allBrands()}
                            labelFormatter={brandName}
                        />
                        <Box paddingLeft={16}>
                            <Input
                                ref={searchRef}
                                value={search.q}
                                onTextChange={q => setSearch({ ...search, q, page: 0 })}
                                type="search"
                                placeholder="Quick search"
                            />
                        </Box>
                        <UserFilters activateFilter={activateFilter} />
                        <MoreActions
                            exportUsers={exportForCurrentFilters}
                            templateUpload={navigateToBatchUpload}
                        />
                    </Box>
                </Box>
                {!!search.filters.length && (
                    <Box paddingBottom={16} wrap>
                        {search.filters.map(f => (
                            <Chip
                                key={f}
                                onDelete={() => deactivateFilter(f)}
                                marginRight={16}
                                label={userListFilterNames[f]}
                            />
                        ))}
                    </Box>
                )}
                <Box paddingVertical={16} flex={1}>
                    <Table>
                        <Thead>
                            <Tr header>
                                <Th>Name</Th>
                                <Th>Email</Th>
                                <Th>Last Login</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {items.map(item => (
                                <TrButton
                                    key={item.objectId}
                                    onClick={() => {
                                        navigate(RouteLink.UserDetails, { id: item.objectId })
                                    }}
                                >
                                    <Td>{`${item.givenName} ${item.surname}`}</Td>
                                    <Td>
                                        {item.emails && item.emails.length ? item.emails[0] : ''}
                                    </Td>
                                    <Td>
                                        {item.lastLogin &&
                                            DateTime.fromISO(item.lastLogin).toLocaleString(
                                                DateTime.DATETIME_SHORT,
                                            )}
                                    </Td>
                                </TrButton>
                            ))}
                        </Tbody>
                    </Table>
                    {loadingAll && (
                        <Box padding={16} paddingTop={32} centered>
                            <Spinner />
                        </Box>
                    )}
                </Box>
                <Box paddingBottom={32} paddingTop={16} justify="flex-end" row>
                    <Total flex={1} paddingLeft={16}>
                        {`Total:  ${total}`}
                    </Total>
                    {total > pageSize && (
                        <Pagination
                            onChange={page => setSearch({ ...search, page: page.index })}
                            page={{ index: search.page, size: pageSize, totalElements: total }}
                        />
                    )}
                </Box>
            </Container>
        </Wrapper>
    )
}

const Container = styled(Box)`
    max-width: 1440px;
    margin: 0 auto;
`

const TrButton = styled(Tr)`
    cursor: pointer;
`

const Wrapper = styled.div`
    height: 100%;
    overflow: auto;
`

const Total = styled(Box)`
    font-weight: 700;
`
