
import { useForm, Controller } from 'react-hook-form'
import styled from 'styled-components'
import React, { FC, useRef, useState } from 'react'
import { Td, Tr } from '../../../components/table/Table'
import { Box } from '../../../components/base/Box'
import { Switch } from '../../../components/form/Switch'
import { UserFormButtons } from '../../users/details/components/UserFormButtons'

import { UserRowEditHint } from '../../users/details/components/UserRowEditHint'

import { useHotkey } from '../../../hooks/useHotkey'

import { Input } from '../../../components/form/Input'
import { Icon } from '../../../components/icon/Icon'

import {Brand} from "../../../common/user/Brand";
import {BrandRole} from "../../../common/user/BrandRole";
import {Application} from "../../../common/user/Application";
import {BrandRoleAssignmentOption} from "../../../services/users/BrandRoleAssignmentOption";

export interface BrandFormData {
    brand: Brand
    roles: BrandRole[]
    userAccountType?: string
}

interface BrandFormProps {
    brandRolesOptions: any
    currentBrand: BrandRoleAssignmentOption
    onSubmit: (data: Application[]) => void
}

export const ApplicationsRow: FC<BrandFormProps> = ({ item, onSubmit }) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            displayName: item.displayName,
            url: item.url,
            imageUrl: item.imageUrl,
            active: item.active,
        },
    })

    const imageUrl = item.imageUrl?.split('/').pop()

    const ref = useRef<HTMLTableRowElement>(null)

    const [show, setShow] = useState(false)
    const toggle = () => {
        setShow(!show)
    }
    useHotkey(show && 'Esc', () => toggle(), [show])

    const onUpdateApplication = (data: Application[]) => {
        toggle()
        onSubmit({
            ...item,
            ...data,
        })
    }
    const handleCancel = () => {
        toggle()
    }

    const readOnlyRow = () => (
        <Tr ref={ref} onClick={toggle} role="button" pointer>
            <Td>{item.idcsGroupName}</Td>
            <Td>{item.displayName}</Td>
            <Td>{item.url}</Td>
            <Td>{item.imageUrl}</Td>
            <ActiveCell>
                {item.active && <Icon className="check-icon" name="check" size={20} />}
            </ActiveCell>
            <UserRowEditHint variant="application" canEdit />
        </Tr>
    )

    const editRow = () => (
        <Tr>
            <EditableCell>{item.idcsGroupName}</EditableCell>
            <EditableCell>
                <Controller
                    control={control}
                    name="displayName"
                    render={({ field }) => <CellInput {...field} />}
                />
            </EditableCell>
            <EditableCell>
                <Controller
                    control={control}
                    name="url"
                    render={({ field }) => <CellInput {...field} />}
                />
            </EditableCell>
            <EditableCell>
                <Controller
                    control={control}
                    name="imageUrl"
                    render={({ field }) => <CellInput {...field} />}
                />
            </EditableCell>
            <EditableCell>
                <Controller
                    control={control}
                    name="active"
                    render={({ field }) => <Switch {...field} checked={field.value} />}
                />
            </EditableCell>
            <EditableCell>
                <Box flex={1} justify="flex-end">
                    <UserFormButtons
                        onCancel={handleCancel}
                        onSubmit={handleSubmit(onUpdateApplication)}
                    />
                </Box>
            </EditableCell>
        </Tr>
    )

    return show ? editRow() : readOnlyRow()
}

const EditableCell = styled(Td)`
    padding: 9px 16px;
`

const ActiveCell = styled(Td)`
    padding: 6px 16px;
`

const CellInput = styled(Input)`
    height: auto;
    padding: 6px 16px;
    font-size: 14px;
    line-height: 16px;
    color: #606069;
`
