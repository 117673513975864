import React, { FC } from 'react'
import styled from 'styled-components'

export const ThreeColorContainer: FC = () => (
    <Container>
        <C1 />
        <C2 />
        <C3 />
    </Container>
)

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 5px;
`

const C1 = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #f1909a;
`

const C2 = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 33%;
    background-color: #e22136;
`

const C3 = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 66%;
    bottom: 0;
    background-color: #a01524;
`
