import styled from 'styled-components'
import { Link } from 'react-router-dom'
import React, { FC, useEffect, useState } from 'react'
import { Box } from '../../components/base/Box'
import { Icon } from '../../components/icon/Icon'
import { Spinner } from '../../components/spinner/Spinner'
import { useRouter } from '../../hooks/useRouter'
import { RouteLink } from '../../Routes'
import { useAuth } from '../../store/auth/authHooks'
import { useUser, useUserActions, useUsers } from '../../store/users/usersHooks'
import { contentOffset, fontFamily, textColor, greyDark } from '../../styles/theme'
import { uniqTmsAccounts } from '../../common/user/TmsAccountWithName'
import { CannotDeleteUserModal } from './CannotDeleteUserModal'
import { DeleteUserConfirmationModal } from './DeleteUserConfirmationModal'
import { UserBrands } from './details/UserBrands'
import { UserForm } from './details/UserForm'
import { UserInfo } from './details/UserInfo'
import { UserFormData } from './details/useUserForm'
import { UserDetailsContainer } from './UserDetailsContainer'
import { Table, Tbody, Td, Th, Thead, Tr } from '../../components/table/Table'
import { Helmet } from 'react-helmet'

export const UserDetailsScreen: FC = () => {
    const { params, navigateHome } = useRouter()
    const { updateInProgress } = useUsers()
    const { currentUser } = useUser(params.id)
    const { updateUser, deleteUser } = useUserActions()
    const { isGlobalAdmin } = useAuth()
    const [showForm, setShowForm] = useState(false)
    const [closeAfterUpdate, setCloseAfterUpdate] = useState(false)
    const [deleteUserVisible, setDeleteUserVisible] = useState(false)
    const [cannotDeleteUserVisible, setCannotDeleteUserVisible] = useState(false)

    useEffect(() => {
        if (closeAfterUpdate && updateInProgress === false) {
            setCloseAfterUpdate(false)
            setShowForm(false)
        }
    }, [closeAfterUpdate, updateInProgress])

    const user = currentUser

    if (!params.id || !user) {
        return (
            <Box fullWidth fullHeight centered>
                <Spinner />
            </Box>
        )
    }

    const toggleForm = () => setShowForm(!showForm)
    const handleSubmit = (data: UserFormData, actionType: 'brand' | 'tms') => {
        setCloseAfterUpdate(true)
        updateUser({
            actionType,
            objectId: data.objectId,
            brandRoles: user.brandRoles,
            portalRoles: user.portalRoles,
            roles: user.roles,
            accountEnabled: data.accountEnabled,

            givenName: data.givenName,
            surname: data.surname,
            jobTitle: data.jobTitle,
            telephoneNumber: data.telephoneNumber,
            mobile: data.mobile,

            companyName: data.companyName,
            useTelematics: data.useTelematics,
            streetAddress: data.streetAddress,
            city: data.city,
            postalCode: data.postalCode,
            country: data.country,

            tmsAccounts: uniqTmsAccounts([...data.userTmsAccounts, ...data.selectedTmsAccounts]),
            requestedTmsAccountNumbers:
                data.selectedTmsAccounts.length > 0 ? [] : user.requestedTmsAccountNumbers,
        })
    }

    const handleDelete = () => {
        deleteUser(user.objectId)
        setDeleteUserVisible(false)
        navigateHome()
    }

    // RFIPI-97 - user delete logic
    /* const tryDelete = () => {
        if (!userCanBeDeleted(user)) {
            setCannotDeleteUserVisible(true)
        } else {
            setDeleteUserVisible(true)
        }
    } */

    return (
        <UserDetailsContainer
            showForm={showForm}
            setShowForm={setShowForm}
            userForm={
                <Box paddingVertical={16} paddingHorizontal={contentOffset}>
                    <UserForm
                        isGlobalAdmin={isGlobalAdmin}
                        user={user}
                        loading={updateInProgress}
                        onSubmit={handleSubmit}
                        onCancel={toggleForm}
                    />
                </Box>
            }
            userInfo={
                <Box paddingHorizontal={contentOffset}>
                    <UserInfo user={user} />
                </Box>
            }
        >
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <ContentBox padding={24} flex={1}>
                <Box justify="space-between" row>
                    <Link to={RouteLink.Home} title="Back">
                        <Icon name="arrow-left" color={textColor} />
                    </Link>
                    {/* // RFIPI-97 - user delete logic */}
                    {/* <Button onClick={tryDelete}>Delete user</Button> */}
                </Box>
                <Header paddingTop={24}>Brands</Header>
                <UserBrands user={user} />
            </ContentBox>
            <DeleteUserConfirmationModal
                isOpen={deleteUserVisible}
                onClose={() => setDeleteUserVisible(false)}
                onConfirm={handleDelete}
            />
            <CannotDeleteUserModal
                isOpen={cannotDeleteUserVisible}
                onClose={() => setCannotDeleteUserVisible(false)}
                onConfirm={() => setCannotDeleteUserVisible(false)}
            />
        </UserDetailsContainer>
    )
}

const ContentBox = styled(Box)`
    overflow: auto;
`

const Header = styled(Box)`
    font-size: 40px;
    font-weight: 300;
    color: ${greyDark};
    font-family: ${fontFamily};
`
