import { MainBrand } from '../../common/user/Brand'
import { BrandRole } from '../../common/user/BrandRole'
import { BrandRoleAssignmentOption } from '../../services/users/BrandRoleAssignmentOption'

export interface BrandsState {
    items: Record<MainBrand, BrandRole[]> | null
    loadingAll: boolean
    updateInProgress: boolean
    brandsActiveRoles: BrandRoleAssignmentOption[]
}

export interface BrandsStateAware {
    brands: BrandsState
}

export const initialBrandsState: BrandsState = {
    items: null,
    loadingAll: false,
    updateInProgress: false,
    brandsActiveRoles: [],
}
