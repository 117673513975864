import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '../../../../components/base/Box'
import { brandsPermissionGuidance } from '../../../../helpers/data/brandsPermissionGuidance'
import { AccountType } from '../../../../common/user/User'

interface UserBrandPermissionsGuidanceProps {
    accountType: string | undefined
}

export const UserBrandPermissionsGuidance: FC<UserBrandPermissionsGuidanceProps> = ({
    accountType,
}) => (
    <Box padding={8}>
        {brandsPermissionGuidance.map((item, counter) => {
            const point = (
                <Box key={counter}>
                    <Title>{item.title}</Title>
                    <p>{item.description}</p>
                </Box>
            )
            if (item.adUser) {
                return accountType === AccountType.AdUser ? point : null
            } else {
                return point
            }
        })}
    </Box>
)

const Title = styled.div`
    font-size: 16px;
    line-height: 32px;
`
