import React, { FC } from 'react'
import styled from 'styled-components'
import { Icon, IconName } from '../icon/Icon'
import { black } from '../../styles/theme'
import { Box } from '../base/Box'

interface TextButtonProps {
    icon: IconName

    onClick?: () => void
}

export const TextButton: FC<TextButtonProps> = props => (
    <Button onClick={props.onClick}>
        <Icon name={props.icon} />
        <Text paddingLeft={16}>{props.children}</Text>
    </Button>
)

export const Button = styled.button`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    padding: 8px;
    cursor: pointer;
    &:hover,
    &:focus {
        outline: none;
    }
`

export const Text = styled(Box)`
    color: ${black};
    font-size: 16px;
`
