import React, { ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'
import { Popover } from '../popover/Popover'
import { Box, MarginBoxProps } from '../base/Box'
import { IconButton } from '../button/IconButton'
import { greyBackground, white } from '../../styles/theme'
import { IconName } from '../icon/Icon'

export interface ListDropdownProps<T extends string> extends MarginBoxProps {
    icon?: IconName
    items: T[]
    onActivate: (f: T) => void
    formatter: (f: T) => ReactNode
}

export const ListDropdown = <T extends string>(props: ListDropdownProps<T>) => {
    const ref = useRef<HTMLDivElement>(null)
    const { onActivate, items, formatter, icon = 'more', ...margins } = props
    const [show, setShow] = useState(false)
    const toggle = () => setShow(!show)

    const chooseFilter = (f: T) => {
        onActivate(f)
        toggle()
    }

    return (
        <Box ref={ref} align="center" row {...margins}>
            <IconButton icon={icon} onClick={() => setShow(!show)} />
            <Popover
                el={ref}
                show={show}
                onClose={toggle}
                horizontalPlacement="right"
                verticalPlacement="bottom"
                withOverlay={false}
            >
                <Wrapper paddingVertical={8}>
                    {items.map(e => (
                        <Item
                            key={e}
                            paddingVertical={16}
                            paddingHorizontal={24}
                            onClick={() => chooseFilter(e)}
                        >
                            {formatter(e)}
                        </Item>
                    ))}
                </Wrapper>
            </Popover>
        </Box>
    )
}

const Wrapper = styled(Box)`
    border-radius: 4px;
    background: ${white};
`

const Item = styled(Box)`
    min-width: 150px;
    cursor: pointer;
    font-size: 16px;
    :hover {
        background: ${greyBackground};
    }
`
