import styled from 'styled-components'
import { animated } from 'react-spring'
import { overlayColor } from '../../styles/theme'

export const Overlay = styled(animated.div).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        !['withOverlay'].includes(prop) && defaultValidatorFn(prop),
})<{ withOverlay?: boolean }>`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${p => (p.withOverlay ? overlayColor : 'transparent')};
`
