import React, { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Box } from '../components/base/Box'
import { greyLight } from '../styles/theme'
import { isDevEnv } from '../helpers/env'
import { RouteLink } from '../Routes'

export const Footer: FC = () => (
    <Container justify="space-between" flex={1}>
        <span>
            © 2023 Terex Corporation. All Rights Reserved. ‘Terex’ is a registered trademark of
            Terex Corporation
        </span>

        <div>
            <ExternalLink target="_blank" href="https://terex.com/privacy-policy">
                Privacy Policy
            </ExternalLink>
            <ExternalLink target="_blank" href="https://www.terex.com/privacy-policy">
                Terms of Use
            </ExternalLink>
            {isDevEnv && (
                <ExternalLink as={Link} {...{ to: RouteLink.StyleGuide }}>
                    Style guide
                </ExternalLink>
            )}
        </div>
    </Container>
)

const Container = styled(Box)`
    color: ${greyLight};
    font-size: 11px;
`

const ExternalLink = styled.a`
    color: inherit;
    text-decoration: none;
    margin-left: 32px;

    &:hover {
        text-decoration: underline;
    }
`
