import { noop } from 'lodash'
import React, { FC, forwardRef, RefAttributes } from 'react'
import styled from 'styled-components'
import { borderRadius, fontFamily, greyBackground, greyLine, textColor } from '../../styles/theme'
import { Stylable } from '../types/Stylable'

interface InputProps extends Stylable, RefAttributes<HTMLInputElement> {
    value?: string
    placeholder?: string
    type?: string
    small?: boolean
    smallText?: boolean
    disabled?: boolean
    italic?: boolean
    onTextChange?: (value: string) => void
    onChange?: (event: any) => void
}

export const Input: FC<InputProps> = forwardRef(
    (
        {
            value,
            onTextChange,
            type,
            placeholder,
            className,
            small = false,
            smallText = false,
            disabled,
            italic,
            onChange,
        },
        ref,
    ) => (
        <StyledInput
            ref={ref}
            value={value}
            onChange={onChange || (event => (onTextChange ?? noop)(event.target.value))}
            className={className}
            type={type}
            disabled={disabled}
            small={small}
            smallText={smallText}
            placeholder={placeholder}
            italic={italic}
        />
    ),
)

const StyledInput = styled.input<{ small?: boolean; smallText?: boolean; italic?: boolean }>`
    width: 100%;
    max-width: 260px;
    height: ${p => (p.small ? '32px' : '38px')};
    padding: 0px 16px;
    border: 1px solid ${greyLine};
    border-radius: ${borderRadius};
    font-size: ${p => (p.smallText ? '13px' : '16px')};
    background: white;
    font-family: ${fontFamily};

    &:focus {
        outline: none;
        border-color: ${textColor};
    }
    &::placeholder {
        font-size: ${p => (p.smallText ? '13px' : '16px')};
        font-family: ${fontFamily};
        font-style: ${p => (p.italic ? 'italic' : 'normal')};

    &:disabled {
        background: ${greyBackground};
    }
`
