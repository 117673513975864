import React, { FC, ButtonHTMLAttributes } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { greyDark2, redTextColor } from '../../styles/theme'
import { Box } from '../base/Box'

export enum ButtonStyle {
    Red = 'red',
    Default = 'default',
}

interface TextButtonProps {
    variant?: ButtonStyle
    type?: ButtonHTMLAttributes<Element>['type']
    small?: boolean
    onClick?: () => void
}

export const LinkButton: FC<TextButtonProps> = ({
    onClick,
    children,
    type = 'button',
    variant = ButtonStyle.Default,
    small = false,
}) => (
    <Button type={type} small={small} onClick={onClick}>
        <Text variant={variant}>{children}</Text>
    </Button>
)

const buttonStyles: Record<ButtonStyle, FlattenSimpleInterpolation> = {
    [ButtonStyle.Red]: css`
        color: ${redTextColor};
    `,
    [ButtonStyle.Default]: css`
        color: ${greyDark2};
    `,
}

const Button = styled.button<{ small?: boolean }>`
    font-size: ${p => (p.small ? '13px' : '14px')};
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    padding: 4px;
    cursor: pointer;
    &:hover,
    &:focus {
        outline: none;
    }
`

const Text = styled(Box)<{ variant: ButtonStyle }>`
    ${p => buttonStyles[p.variant]}
    font-size: 13px;
`
