import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { BrandRole, BrandRoleDocuments, BrandRoleBusiness } from '../../common/user/BrandRole'
import * as actions from './brandsActions'
import { initialBrandsState, BrandsState } from './brandsState'

export const brandsReducer = reducerWithInitialState(initialBrandsState)
    .case(actions.load.started, (state): BrandsState => ({ ...state, loadingAll: true }))
    .case(
        actions.load.failed,
        (state): BrandsState => ({
            ...state,
            loadingAll: false,
            items: initialBrandsState.items,
        }),
    )
    .case(
        actions.load.done,
        (state, { result }): BrandsState => ({
            ...state,
            items: result,
            loadingAll: false,
        }),
    )

    .case(actions.update.started, (state): BrandsState => ({ ...state, updateInProgress: true }))
    .case(actions.update.failed, (state): BrandsState => ({ ...state, updateInProgress: false }))
    .case(
        actions.update.done,
        (state, { result, params }): BrandsState => ({
            ...state,
            items: updateBrandsItems(state, result.response, params.brand),
            updateInProgress: false,
        }),
    )

    .case(
        actions.getActiveBrandRoles.started,
        (state): BrandsState => ({ ...state, loadingAll: true }),
    )
    .case(
        actions.getActiveBrandRoles.failed,
        (state): BrandsState => ({
            ...state,
            loadingAll: false,
            brandsActiveRoles: initialBrandsState.brandsActiveRoles,
        }),
    )
    .case(
        actions.getActiveBrandRoles.done,
        (state, { result }): BrandsState => ({
            ...state,
            brandsActiveRoles: formatBrandsActiveRoles(result),
            loadingAll: false,
        }),
    )

const formatBrandsActiveRoles = result => {
    const output = Object.entries(result).map(([mainBrand, rolesArray]) => {
        const business = rolesArray.filter(
            r => BrandRoleBusiness.includes(r) && r !== BrandRole.BrandAdmin,
        )
        const documents = rolesArray.filter(
            r => BrandRoleDocuments.includes(r) && r !== BrandRole.BrandAdmin,
        )

        return {
            brand: { mainBrand },
            roles: { business, documents },
        }
    })

    return output
}

const updateBrandsItems = (state, updatedItem, brand) => {
    const items = { ...state.items }
    items[brand] = updatedItem

    return items
}
