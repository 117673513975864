import { Application } from '../../common/user/Application'

export interface ApplicationsState {
    items: Application[] | []
    loadingAll: boolean
    updateInProgress: boolean
}

export interface ApplicationsStateAware {
    applications: ApplicationsState
}

export const initialApplicationsState: ApplicationsState = {
    items: [],
    loadingAll: false,
    updateInProgress: false,
}
