import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { load, update } from './applicationsActions'
import { ApplicationsStateAware } from './applicationsState'

export const useApplications = () => {
    const actions = useActions({
        loadApplications: load.started,
    })
    const applicationsState = useSelector((state: ApplicationsStateAware) => state.applications)

    return {
        ...applicationsState,
        ...actions,
    }
}

export const useApplicationActions = () => {
    const actions = useActions({
        updateApplication: update.started,
    })
    const applicationsState = useSelector((state: ApplicationsStateAware) => state.applications)

    return {
        ...applicationsState,
        ...actions,
    }
}
