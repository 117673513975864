import { format } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { Application } from '../../common/user/Application'

class ApplicationService {
    private rest = new BaseRestService()

    public findAll = () => this.rest.getAndTransform<Application[]>(`/config/applications`)

    public update = (payload: Application) =>
        this.rest.postJson(
            format({
                pathname: `/config/applications`,
            }),
            payload,
        )
}

export const applicationsService = new ApplicationService()
