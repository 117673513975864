import React, { FC } from 'react'
import { Box } from '../../components/base/Box'
import { H1 } from '../../components/base/Typography'

export const Forbidden: FC = () => (
    <Box>
        <H1>Welcome to Admin panel</H1>
        <div>You have not been granted access yet.</div>
    </Box>
)
