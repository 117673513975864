import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Application } from '../../common/user/Application'
import * as actions from './applicationsActions'
import { initialApplicationsState, ApplicationsState } from './applicationsState'

export const applicationsReducer = reducerWithInitialState(initialApplicationsState)
    .case(actions.load.started, (state): ApplicationsState => ({ ...state, loadingAll: true }))
    .case(
        actions.load.failed,
        (state): ApplicationsState => ({
            ...state,
            loadingAll: false,
            items: initialApplicationsState.items,
        }),
    )
    .case(
        actions.load.done,
        (state, { result }): ApplicationsState => ({
            ...state,
            items: result,
            loadingAll: false,
        }),
    )

    .case(
        actions.update.started,
        (state): ApplicationsState => ({ ...state, updateInProgress: true }),
    )
    .case(
        actions.update.failed,
        (state): ApplicationsState => ({ ...state, updateInProgress: false }),
    )
    .case(
        actions.update.done,
        (state, { result }): ApplicationsState => ({
            ...state,
            items: mergeUpdatedApplication(state.items, JSON.parse(result.request.body)),
            updateInProgress: false,
        }),
    )

const mergeUpdatedApplication = (current: Application[], updated: Application) => {
    var foundIndex = current.findIndex(x => x.id === updated.id)
    current[foundIndex] = updated

    return current
}
