import { Observable, of } from 'rxjs'
import { Action } from 'redux'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { AsyncActionCreators } from 'typescript-fsa'
import { StateObservable } from 'redux-observable'

export const asyncLoadEpic = <TRequest, TResponse, TState>(
    actionCreator: AsyncActionCreators<TRequest, TResponse>,
    callProvider: (payload: TRequest, state: StateObservable<TState>) => Observable<TResponse>,
) => (actions$: Observable<Action>, state$: StateObservable<TState>): Observable<Action> =>
    actions$.pipe(
        filter(actionCreator.started.match),
        switchMap(({ payload }) =>
            callProvider(payload, state$).pipe(
                map(result =>
                    actionCreator.done({
                        params: payload,
                        result,
                    }),
                ),
                catchError(error =>
                    of(
                        actionCreator.failed({
                            params: payload,
                            error,
                        }),
                    ),
                ),
            ),
        ),
    )
