import { useForm } from '../../../hooks/form/useForm'
import { User, UserInfo } from '../../../common/user/User'
import { TmsAccountOption, TmsAccountWithName } from '../../../common/user/TmsAccountWithName'

export interface UserFormData extends UserInfo {
    objectId: string
    accountEnabled: boolean

    selectedTmsAccounts: TmsAccountOption[]
    userTmsAccounts: TmsAccountWithName[]
    newTmsAccountNumber: string
}

export const useUserForm = (user: User) => {
    const initialState: UserFormData = {
        objectId: user.objectId,
        accountEnabled: user.accountEnabled,

        selectedTmsAccounts: [],
        userTmsAccounts: user.tmsAccounts || [],
        newTmsAccountNumber: '',

        jobTitle: user.jobTitle || '',
        givenName: user.givenName || '',
        surname: user.surname || '',
        telephoneNumber: user.telephoneNumber || '',
        mobile: user.mobile || '',

        companyName: user.companyName || '',
        streetAddress: user.streetAddress || '',
        city: user.city || '',
        postalCode: user.postalCode || '',
        country: user.country,
        useTelematics: user.useTelematics,
    }

    // https://terexcorp.atlassian.net/browse/PWE-441 Disabled required fields for now
    // const requiredField = required('This field is required')
    return useForm<UserFormData>(
        {
            /* givenName: requiredField,
            surname: requiredField,
            mobile: requiredField,

            streetAddress: requiredField,
            city: requiredField,
            postalCode: requiredField, */
        },
        { initialState },
    )
}
