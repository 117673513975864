/* eslint-disable react/jsx-no-literals, no-console */
import React, { FC, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box } from '../../components/base/Box'
import { H1, H2 } from '../../components/base/Typography'
import { Button } from '../../components/button/Button'
import { Icon, IconName } from '../../components/icon/Icon'
import { icons } from '../../components/icon/icons'
import { Table, Tbody, Td, Th, Thead, Tr } from '../../components/table/Table'
import { Spinner } from '../../components/spinner/Spinner'
import { greyLine } from '../../styles/theme'
import { Wrapper } from '../../components/base/Wrapper'
import { Popover } from '../../components/popover/Popover'
import { Input } from '../../components/form/Input'
import { Checkbox } from '../../components/form/Checkbox'
import { SimpleSelect } from '../../components/form/SimpleSelect'
import { Field } from '../../components/form/Field'
import { Switch } from '../../components/form/Switch'
import { Helmet } from 'react-helmet';

export const StyleGuideScreen: FC = () => {
    const popoverRef = useRef<HTMLDivElement>(null)
    const [showPopover, setShowPopover] = useState(false)

    return (
        <Wrapper paddingBottom={32}>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>

            <H1>Style guide</H1>
            <Section>
                <H2>Icons</H2>
                <Columns>
                    {Object.keys(icons).map(name => (
                        <Box key={name} centered column>
                            <Icon name={name as IconName} />
                            {name}
                        </Box>
                    ))}
                </Columns>
            </Section>

            <Section>
                <H2>Button</H2>
                <Columns>
                    <Button>Button</Button>
                    <Button variant="primary">Button</Button>
                    <Button variant="secondary">Button</Button>
                    <Button icon="check">Button</Button>
                    <Button icon="edit" variant="primary">
                        Button
                    </Button>
                    <Button icon="plus" variant="secondary">
                        Button
                    </Button>
                </Columns>
            </Section>

            <Section>
                <H2>Forms</H2>

                <Field label="Input">
                    <Input placeholder="Placeholder" onTextChange={console.log} />
                </Field>

                <Field label="Switch">
                    <Switch checked>Label</Switch>
                    <Switch checked={false} />
                </Field>

                <Field label="Checkbox" as="div">
                    <Checkbox title="Active" onChange={console.log} />
                    <Checkbox title="Enabled" onChange={console.log} />
                    <Checkbox title="Cool" onChange={console.log} />
                </Field>

                <Field label="Select">
                    <SimpleSelect
                        value="First"
                        options={['First', 'Second', 'Third']}
                        onChange={console.log}
                    />
                </Field>
            </Section>

            <Section>
                <H2>Spinner</H2>
                <Spinner />
            </Section>

            <Section>
                <H2>Popover</H2>
                <div ref={popoverRef}>
                    <Button onClick={() => setShowPopover(true)}>Show</Button>
                </div>
                <Popover
                    el={popoverRef}
                    show={showPopover}
                    onClose={() => setShowPopover(false)}
                    padding={8}
                >
                    <H2>Hello Popover!</H2>
                    <Button onClick={() => setShowPopover(false)}>Hide</Button>
                </Popover>
            </Section>

            <Section>
                <H2>Table</H2>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Email</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>Jan Kowalski</Td>
                            <Td>jankowalski@example.org</Td>
                        </Tr>
                        <Tr>
                            <Td>Jan Kowalski</Td>
                            <Td>jankowalski@example.org</Td>
                        </Tr>
                        <Tr>
                            <Td>Jan Kowalski</Td>
                            <Td>jankowalski@example.org</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Section>
        </Wrapper>
    )
}

const Section = styled.div`
    & + & {
        margin-top: 32px;
        padding-top: 16px;
        border-top: 1px solid ${greyLine};
    }
`

const Columns = styled.div`
    display: grid;
    width: auto;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-auto-rows: min-content;
    grid-gap: 16px;
`
