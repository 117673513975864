import { FC } from 'react'
import ReactDOM from 'react-dom'

export const Portal: FC = ({ children }) => {
    const container = document.getElementById('overlays')

    if (!container) {
        throw new Error('[Portal] #overlays element is missing')
    }

    return ReactDOM.createPortal(children, container)
}
