import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Wrapper, WrapperContainer } from '../../components/base/Wrapper'
import { Logo } from '../../components/logo/Logo'
import { greyLine } from '../../styles/theme'
import { Navigation } from './Navigation'

interface LayoutProps {
    user: ReactNode
    footer: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children, user, footer }) => (
    <Container>
        <TopPanel>
            <WrapperContainer justify="space-between" paddingLeft={32} paddingRight={32} row>
                <LogoLink to="/users">
                    <Logo />
                </LogoLink>
                <Navigation />
                {user}
            </WrapperContainer>
        </TopPanel>
        <Main>{children}</Main>
        <FooterPanel>
            <Wrapper align="center">{footer}</Wrapper>
        </FooterPanel>
    </Container>
)

const panelHeight = '64px'

const Container = styled.div`
    height: 100%;
    padding: ${panelHeight} 0;
`

const TopPanel = styled.header`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-bottom: 1px solid ${greyLine};
    height: ${panelHeight};
`

const LogoLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`

const Main = styled.main`
    position: relative;
    flex-grow: 1;
    height: 100%;
`

const FooterPanel = styled.footer`
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${panelHeight};
    flex-shrink: 0;
    background-color: white;
    border-top: 1px solid ${greyLine};
`
