import { SpringConfig } from 'react-spring'

export type NumberValues = 0 | 4 | 8 | 16 | 24 | 32 | 40

export const contentOffset: NumberValues = 32

export const brandColor = '#cc0000'
export const textColor = '#383844'
export const redTextColor = '#e22136'
export const redLightBackground = '#fef9fa'
export const redBorder = '#f1909a'

export const white = '#fff'
export const black = '#000'

export const greyBackground = '#f5f5f5'
export const greyLight = '#acacac'
export const greyLight2 = '#afafb4'
export const greyLine = '#dfdfdf'
export const greyLine2 = '#606069'
export const greyDark = '#383844'
export const greyDark2 = '#625f5d'

export const genieBlue = '#0072BC'

export const disabledColor = '#cccaca'

export const okColor = '#29b242'
export const okBackground = '#eafbed'

export const warningColor = '#fa8400'

export const shadowColor = 'rgba(0, 0, 0, 0.15)'
export const overlayColor = 'rgba(255, 255, 255, 0.5)'

export const boxShadowColor = '0 0 8px rgba(0, 0, 0, 0.2)'

export const borderRadius = '4px'
export const fontFamily = '"Roboto", sans-serif'
export const titleFontFamily = '"Roboto Condensed", sans-serif'

export const transitionFast = 'ease 150ms'

export const springConfig: SpringConfig = {
    mass: 1,
    tension: 350,
    friction: 35,
}
