import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '../../../../components/base/Box'
import { Icon } from '../../../../components/icon/Icon'
import { warningColor } from '../../../../styles/theme'

interface UserTmsAccountsVerificationStatusProps {
    requestedTmsAccountNumbers: string[]
}

export const UserTmsAccountsVerificationStatus: FC<UserTmsAccountsVerificationStatusProps> = ({
    requestedTmsAccountNumbers,
}) =>
    requestedTmsAccountNumbers.length > 0 ? (
        <Box paddingTop={16} flex={1} row>
            <Box alignSelf="flex-start">
                <Icon size={16} name="exclamationRound" color={warningColor} />
            </Box>
            <Box>
                <Box row>
                    <Text paddingLeft={8} bold>
                        User status:
                    </Text>
                    <Warning paddingLeft={8} bold>
                        Not verified
                    </Warning>
                </Box>
                <Text paddingLeft={8} paddingTop={16}>
                    Please, verify pending TMS Accounts Numbers requested by user during
                    self-registration in the user's edit panel.
                </Text>
            </Box>
        </Box>
    ) : null

const Warning = styled(Box)<{ bold?: boolean }>`
    color: ${warningColor};
    font-size: 12px;
    font-weight: ${p => (p.bold ? '700' : '400')};
`

const Text = styled(Box)<{ bold?: boolean }>`
    font-size: 12px;
    font-weight: ${p => (p.bold ? '700' : '400')};
`
