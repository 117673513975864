import styled from 'styled-components'
import { animated } from 'react-spring'
import React, { FC, ReactNode } from 'react'
import { Box } from '../../components/base/Box'
import { Wrapper } from '../../components/base/Wrapper'
import { IconButton } from '../../components/button/IconButton'
import { Icon } from '../../components/icon/Icon'
import { useHotkey } from '../../hooks/useHotkey'
import {
    contentOffset,
    greyBackground,
    greyLine,
    overlayColor,
    greyLine2,
} from '../../styles/theme'
import { panelSize, usePanelAnimation } from './details/usePanelAnimation'

export interface UserDetailsContainerProps {
    showForm: boolean
    userForm: ReactNode
    userInfo: ReactNode
    setShowForm: (v: boolean) => void
}

export const UserDetailsContainer: FC<UserDetailsContainerProps> = ({
    children,
    showForm,
    setShowForm,
    userForm,
    userInfo,
}) => {
    useHotkey('E', () => setShowForm(true))
    const {
        formTransition,
        profileTransition,
        backgroundAnimation,
        overlayAnimation,
    } = usePanelAnimation(showForm)

    const toggleForm = () => setShowForm(!showForm)

    return (
        <Container align="stretch" paddingHorizontal={0} row>
            <UserColumn style={{ width: panelSize.close }}>
                <Box
                    marginTop={contentOffset}
                    marginBottom={24}
                    marginHorizontal={contentOffset}
                    justify="space-between"
                    align="center"
                    row
                >
                    <UserIcon name="user" color={greyLine2} />
                    {!showForm && (
                        <IconButton
                            icon="edit-profile"
                            color={greyLine2}
                            onClick={toggleForm}
                            title="Edit"
                            small
                        />
                    )}
                </Box>

                {formTransition.map(
                    ({ item, key, props }) =>
                        item && (
                            <FormWrapper key={key} style={{ ...props, width: panelSize.open }}>
                                {userForm}
                            </FormWrapper>
                        ),
                )}

                {profileTransition.map(
                    ({ item, key, props }) =>
                        item && (
                            <UserInfoContainer key={key} style={props}>
                                {userInfo}
                            </UserInfoContainer>
                        ),
                )}

                <AnimatedBackground style={backgroundAnimation} />
            </UserColumn>
            <AnimatedOverlay style={overlayAnimation} />
            {children}
        </Container>
    )
}

const Container = styled(Wrapper)`
    height: 100%;
    overflow: hidden;
    padding-left: 1px;
`

const UserColumn = styled.div`
    position: relative;
    z-index: 2;
    height: 100%;
`

const UserIcon = styled(Icon)`
    width: 72px;
    height: 74px;
`

const AnimatedBackground = styled(animated.div)`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    padding-right: 1px;
    background: ${greyBackground};
    outline: 1px solid ${greyLine};
`

const FormWrapper = styled(animated.div)`
    overflow: auto;
    top: 120px;
    bottom: 0;
`

const AnimatedOverlay = styled(animated.div)`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${overlayColor};
`

const UserInfoContainer = styled(animated.div)`
    max-width: 100%;
    overflow: hidden;
`
