import React, { FC, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Wrapper } from '../../components/base/Wrapper'
import { Popover } from '../../components/popover/Popover'
import { Box } from '../../components/base/Box'

import { RouteLink } from '../../Routes'

import { useUsers } from '../../store/users/usersHooks'

export const Navigation: FC = () => {
    const { setSearch } = useUsers()

    const location = useLocation()

    const ref = useRef<HTMLDivElement>(null)
    const [show, setShow] = useState(false)

    const activeClass = /^\/settings(\/.*)?$/.test(location.pathname) ? 'active' : ''

    const isUsersPage = /^\/users(\/.*)?$/.test(location.pathname)

    const toggle = () => setShow(!show)

    // Reset Users list search queries
    React.useEffect(() => {
        setShow(false)
        if (isUsersPage) {
            setSearch({
                q: '',
                page: 0,
                pageSize: 20,
                filters: [],
            })
        }
    }, [location])

    return (
        <Box ref={ref} align="center" row fullHeight>
            <MenuItem to={RouteLink.Home}>Users</MenuItem>
            <MenuItem as="span" onClick={() => setShow(!show)} className={activeClass}>
                Settings
            </MenuItem>
            <Popover
                el={ref}
                show={show}
                onClose={toggle}
                horizontalPlacement="right"
                verticalPlacement="bottom"
                withOverlay={false}
            >
                <Wrapper paddingVertical={32} paddingHorizontal={24} column>
                    <NavigationLink to={RouteLink.SettingsApplications}>
                        Applications
                    </NavigationLink>
                    <NavigationLink to={RouteLink.SettingsBrandPermissions}>
                        Default Brand Permissions
                    </NavigationLink>
                </Wrapper>
            </Popover>
        </Box>
    )
}

const NavigationLink = styled(NavLink)`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #343434;
    text-decoration: none;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
`

const MenuItem = styled(NavLink)`
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #343434;
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 40px;
    }

    &.active {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 100%;
            background: #ce1b2e;
        }
    }
`
