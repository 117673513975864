import config from 'config.json'

export const appConfig: AppConfig = config

export interface AppConfig {
    idcsHost: string
    clientId: string
    oauthScopes: string[]
    redirectUri: string
    editProfileUrl: string
    apiUrl: string
    adminOnlyFromTerex: boolean
}

export const isPublicPath = (path: string) => path.startsWith(config.idcshost)
