import { getSubBrands, hasSubBrand, isValidBrand, MainBrand } from './Brand'
import { BrandRole } from './BrandRole'
import { isValidPortal } from './Portal'
import { PortalRole } from './PortalRole'
import { BrandRoleAssignment, PortalRoleAssignment } from './User'

export enum Role {
    GlobalAdmin = 'B2C_ADMIN',
}
const idcsGlobalAdminPrefix = 'myAdmin-Administrators'
const idcsBrandAdminPhrase = 'Managers'

export const checkGlobalAdminRole = (rawGroups: string[]): boolean => {
    for (const rawGroup of rawGroups) {
        if (rawGroup.startsWith(idcsGlobalAdminPrefix)) {
            return true
        }
    }

    return false
}

export const idcsGroupsToBrandRoles = (rawGroups: string[]): BrandRoleAssignment[] => {
    const brandRoleAssignment: BrandRoleAssignment[] = []
    for (const rawGroup of rawGroups) {
        const parts = rawGroup.split('-')
        if (parts.length < 2) {
            continue
        }

        const diffBrand = handleDifferentNameForKnownGroup(parts[1], parts[2])
        if (diffBrand) {
            brandRoleAssignment.push(diffBrand)
            continue
        }

        const brand = parts[1].replace('_', '').toUpperCase()
        if (parts[2] === idcsBrandAdminPhrase && isValidBrand(brand)) {
            const brands = hasSubBrand(brand) ? getSubBrands(brand) : undefined
            if (brands) {
                brands.forEach(brand =>
                    brandRoleAssignment.push({
                        brand,
                        role: BrandRole.BrandAdmin,
                    }),
                )
            } else {
                brandRoleAssignment.push({
                    brand: {
                        mainBrand: brand,
                        subBrand: undefined,
                    },
                    role: BrandRole.BrandAdmin,
                })
            }
        }
    }

    return brandRoleAssignment
}

export const idcsGroupsToPortalRoles = (rawGroups: string[]): PortalRoleAssignment[] => {
    const portalRoleAssignment: PortalRoleAssignment[] = []
    for (const rawGroup of rawGroups) {
        const parts = rawGroup.split('-')
        if (parts.length < 2) {
            continue
        }

        const portal = parts[1].replace('_', '').toUpperCase()
        if (parts[2] === 'Managers' && isValidPortal(portal)) {
            portalRoleAssignment.push({
                portal,
                role: PortalRole.PortalAdmin,
            })
        }
    }

    return portalRoleAssignment
}

const handleDifferentNameForKnownGroup = (
    groupNamePart: string,
    permission: string,
): BrandRoleAssignment | undefined => {
    if (groupNamePart === 'Advance' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.AdvanceMixer,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'Genielift' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Awp,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'Washing_Systems' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Tws,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'Recycling_Systems' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Trs,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'RTCranes_EMEAR_APAC' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.RT_Cranes,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'RTCranes_AMER' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.RT_Cranes,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'TowerCranes' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Tower_Cranes,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'Canica' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Canica,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'Simplicity' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Simplicity,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'MPS' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Terex,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'Cedarapids' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Cedarapids,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'Green_Tec' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Green_Tec,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    if (groupNamePart === 'Magna' && permission === idcsBrandAdminPhrase) {
        return {
            brand: {
                mainBrand: MainBrand.Magna,
                subBrand: undefined,
            },
            role: BrandRole.BrandAdmin,
        }
    }

    return undefined
}
