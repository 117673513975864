import { useState } from 'react'

export type ValidatorResult = string | undefined
export type Validator<T> = (value: T) => ValidatorResult
export type Validators<TData> = { [TKey in keyof TData]?: Validator<TData[TKey]> }

interface UseFormOptions<TData> {
    initialState: TData
}

export const useForm = <TData extends {}>(
    validators: Validators<TData>,
    options: UseFormOptions<TData>,
) => {
    type FieldName = keyof TData
    const [values, setValues] = useState<TData>(options.initialState)

    type ValidatorErrors = Record<FieldName, ValidatorResult>
    type ShowErrors = Partial<Record<FieldName, boolean>>

    const getAllErrors = (): ValidatorErrors =>
        Object.fromEntries(
            Object.entries(validators).map(entries => {
                const [key, validator] = entries as [FieldName, Validator<TData[FieldName]>]
                const error = validator(values[key])
                return [key, error]
            }),
        ) as ValidatorErrors

    const [errors, setErrors] = useState<ValidatorErrors>(getAllErrors())
    const [showFieldError, setShowFieldError] = useState<ShowErrors>({})
    const [showAllErrors, setAllError] = useState(false)

    const validateField = <T extends FieldName>(
        fieldName: T,
        value: TData[T] = values[fieldName],
    ) => {
        const validator = validators[fieldName]
        if (validator) {
            return validator(value)
        }
        return undefined
    }

    const updateField = <T extends FieldName>(fieldName: T, value: TData[T]) => {
        setValues(values => ({
            ...values,
            [fieldName]: value,
        }))
        setErrors(errors => ({
            ...errors,
            [fieldName]: validateField(fieldName, value),
        }))
    }

    const touchField = (fieldName: FieldName) => {
        setShowFieldError(showFieldError => ({
            ...showFieldError,
            [fieldName]: true,
        }))
        setErrors(errors => ({
            ...errors,
            [fieldName]: validateField(fieldName),
        }))
    }

    const fieldError = (fieldName: FieldName) => {
        if ((showAllErrors || showFieldError[fieldName]) && errors[fieldName]) {
            return errors[fieldName]
        }
        return undefined
    }

    const submitHandler = (callback: (values: TData, actionType: 'brand' | 'tms') => void) => () => {
        setAllError(true)

        const allErrors = getAllErrors()

        setErrors(allErrors)

        const hasErrors = Object.values(allErrors).some(error => typeof error === 'string')

        if (!hasErrors) {
            callback(values, 'tms')
        }
    }

    const reset = () => setValues(options.initialState)

    return { submitHandler, updateField, touchField, fieldError, values, reset }
}
