import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { AuthUserExtractor } from '../../common/auth/AuthUserExtractor'
import * as actions from './authActions'
import { AuthState, initialAuthState } from './authState'

export const authReducer = reducerWithInitialState(initialAuthState)
    .case(
        actions.setToken,
        (state, result): AuthState => {
            if (result.accessToken) {
                const authData = {
                    ...state.authData!,
                    ...result,
                }
                return {
                    ...state,
                    authData,
                    authUser: AuthUserExtractor.extractAuthUser(
                        authData.accessToken,
                        authData.groups,
                    ),
                }
            }
            return state
        },
    )
    .case(
        actions.logout,
        (state): AuthState => ({
            ...state,
            authData: undefined,
            authUser: undefined,
        }),
    )
