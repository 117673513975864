import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { editProfile, logout } from './authActions'
import { AuthStateAware } from './authState'

export const useAuth = () => {
    const actions = useActions({ logout, editProfile })
    const user = useSelector((state: AuthStateAware) => state.auth)
    const idToken = user.authData ? user.authData?.idToken : undefined
    const availableBrands = user.authUser ? user.authUser.getAvailableBrands() : []
    const availablePortals = user.authUser ? user.authUser.getAvailablePortals() : []
    const isGlobalAdmin = user.authUser ? user.authUser.isGlobalAdmin() : false
    return {
        user,
        availableBrands,
        availablePortals,
        isGlobalAdmin,
        editProfile: () => actions.editProfile(),
        logout: () => actions.logout({ purge: true, idToken }),
    }
}
