import { actionCreatorFactory } from 'typescript-fsa'
import { UsersExportRequest, UsersRequest } from '../../common/user/UserRequest'
import { Pageable } from '../../common/pageable/Pageable'
import { ObjectIdAware, User, UserUpdatePayload } from '../../common/user/User'
import { TmsAccountOption, TmsAccountsRequestQuery } from '../../common/user/TmsAccountWithName'

const action = actionCreatorFactory('USERS')

export const load = action.async<UsersRequest, Pageable<User>>('LOAD')

export const exportUsers = action<UsersExportRequest>('EXPORT')

export const loadUser = action.async<string, User>('LOAD_USER')

export const loadUserAccounts = action.async<TmsAccountsRequestQuery, TmsAccountOption[]>(
    'LOAD_USERS_TMS_ACCOUNTS',
)

export const deleteUser = action.async<string, void>('DELETE_USER')

export const update = action.async<UserUpdatePayload & ObjectIdAware, User>('UPDATE')

export const setFilters = action<UsersRequest>('SET_FILTERS')

export const verifyTmsAccounts = action.async<TmsAccountsRequestQuery, TmsAccountOption[]>(
    'VERIFY_TMS_ACCOUNT_OPTIONS',
)

export const addAndVerifyTmsAccount = action.async<TmsAccountsRequestQuery, TmsAccountOption[]>(
    'ADD_TMS_ACCOUNT_OPTIONS',
)

export const resetTmsAccountsOptions = action('RESET_TMS_ACCOUNT_OPTIONS')

export const clearTmsAccountsOptions = action('CLEAR_TMS_ACCOUNT_OPTIONS')
