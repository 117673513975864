/* eslint-disable @typescript-eslint/no-untyped-public-signature */
import { Observable, of } from 'rxjs'
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax'
import { flatMap, map, switchMap } from 'rxjs/operators'
import { UsersExportResponseStatus, UsersExportResponseStatuses } from '../../common/user/UserRequest'

export type RequestInterceptorParam = [string, AjaxRequest | undefined]
export type RequestInterceptor = (
    requestParam: RequestInterceptorParam,
) => Observable<RequestInterceptorParam>
export type ResponseInterceptor = (response: AjaxResponse) => Observable<AjaxResponse>

export interface Headers {
    [key: string]: string
}

const contentType = 'Content-Type'

export class BaseRestService {
    public static requestInterceptors: RequestInterceptor[] = []
    public static responseInterceptors: ResponseInterceptor[] = []

    public static jsonTransformer<T>(response: AjaxResponse): T {
        return response.response
    }

    public static textTransformer(response: AjaxResponse): string {
        return response.responseText
    }

    public static blobTransformer(r: AjaxResponse): Blob {
        return new Blob([r.response], {
            type: (r.xhr as any).getResponseHeader(contentType) || 'text/plain',
        })
    }

    public static fileDownloadTransformer(r: AjaxResponse): Observable<[Blob, string]> {
        const blob = BaseRestService.blobTransformer(r)
        const filename = BaseRestService.getFilenameFromHeader(
            (r.xhr as any).getResponseHeader('Content-Disposition'),
        )
        if (filename) {
            return of([blob, filename] as [Blob, string])
        }
        throw new Error('missing filename in download response')
    }

    public static exportStatus(response: AjaxResponse): Observable<UsersExportResponseStatus> {
        const status = response.response.status

        if (status === UsersExportResponseStatuses.FAILED) {
            throw new Error('User export status is FAILED')
        }
        return of(status)
    }

    public static getFilenameFromHeader = (headerValue: string | null) => {
        if (headerValue?.includes('attachment')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(headerValue)
            if (matches?.[1]) {
                return matches[1].replace(/['"]/g, '')
            }
        }
        return undefined
    }

    public post(url: string, body?: any, headers?: Headers): Observable<AjaxResponse> {
        return this.request(url, {
            method: 'POST',
            body,
            headers,
        })
    }

    public get(url: string, headers?: Headers): Observable<AjaxResponse> {
        return this.request(url, {
            method: 'GET',
            headers,
        })
    }

    public getBinary(url: string, headers?: Headers, timeout?: number): Observable<AjaxResponse> {
        return this.request(url, {
            method: 'GET',
            headers,
            responseType: 'arraybuffer',
            timeout,
        })
    }

    public getWithBody(url: string, headers?: Headers): Observable<AjaxResponse> {
        return this.request(url, {
            method: 'GET',
            body: {},
            headers,
        })
    }

    public deleteReq(url: string, headers?: Headers): Observable<AjaxResponse> {
        return this.request(url, {
            method: 'DELETE',
            headers,
        })
    }

    public patch(url: string, body?: any, headers?: Headers): Observable<AjaxResponse> {
        return this.request(url, {
            method: 'PATCH',
            body,
            headers,
        })
    }

    public put(url: string, headers?: Headers): Observable<AjaxResponse> {
        return this.request(url, {
            method: 'PUT',
            headers,
        })
    }

    public postJson(url: string, body: any, headers?: Headers): Observable<AjaxResponse> {
        return this.post(url, body, this.addJsonContentType(headers))
    }

    public postForm(url: string, body: any, headers?: Headers): Observable<AjaxResponse> {
        return this.post(url, body, this.addFormUrlEncodedContentType(headers))
    }

    public patchJson(url: string, body: any, headers?: Headers): Observable<AjaxResponse> {
        return this.patch(url, body, this.addJsonContentType(headers))
    }

    public request(url: string, init?: AjaxRequest, timeout?: number): Observable<AjaxResponse> {
        return this.wrapRequest([url, init]).pipe(
            flatMap(([nextUrl, nextInit]: [string, AjaxRequest]) =>
                BaseRestService.responseInterceptors.reduce(
                    (observable, interceptor) => observable.pipe(flatMap(interceptor)),
                    ajax({ ...nextInit, url: nextUrl, timeout: timeout || 120000 }),
                ),
            ),
        )
    }

    public putAndTransform<T>(
        url: string,
        transformer: (response: AjaxResponse) => T = BaseRestService.jsonTransformer,
    ): Observable<T> {
        return this.put(url).pipe(map(transformer))
    }

    public postJsonAndTransform<T>(
        url: string,
        body: any,
        transformer: (response: AjaxResponse) => T = BaseRestService.jsonTransformer,
    ): Observable<T> {
        return this.postJson(url, body).pipe(map(transformer))
    }

    public postFormAndTransform<T>(
        url: string,
        body: any,
        transformer: (response: AjaxResponse) => T = BaseRestService.jsonTransformer,
    ): Observable<T> {
        return this.postForm(url, body).pipe(map(transformer))
    }

    public getAndTransform<T>(
        url: string,
        transformer: (response: AjaxResponse) => T = BaseRestService.jsonTransformer,
    ): Observable<T> {
        return this.get(url).pipe(map(transformer))
    }

    public getAndDownload(url: string): Observable<[Blob, string]> {
        return this.getBinary(url, undefined, 60000).pipe(
            switchMap(r => BaseRestService.fileDownloadTransformer(r)),
        )
    }

    public getExportStatus(url: string): Observable<UsersExportResponseStatus> {
        return this.get(url).pipe(
            switchMap(r => BaseRestService.exportStatus(r)),
        )
    }

    public getJsonAndTransform<T>(
        url: string,
        transformer: (response: AjaxResponse) => T = BaseRestService.jsonTransformer,
    ): Observable<T> {
        return this.get(url).pipe(map(transformer))
    }

    public deleteAndTransform<T>(
        url: string,
        transformer: (response: AjaxResponse) => T = BaseRestService.jsonTransformer,
    ): Observable<T> {
        return this.deleteReq(url).pipe(map(transformer))
    }

    public patchAndTransform<T>(
        url: string,
        transformer: (response: AjaxResponse) => T = BaseRestService.jsonTransformer,
    ): Observable<T> {
        return this.patch(url).pipe(map(transformer))
    }

    public patchJsonAndTransform<T>(
        url: string,
        body: any,
        transformer: (response: AjaxResponse) => T = BaseRestService.jsonTransformer,
    ): Observable<T> {
        return this.patchJson(url, body).pipe(map(transformer))
    }

    private wrapRequest(param: RequestInterceptorParam): Observable<[string, AjaxRequest]> {
        const currentObservable = BaseRestService.requestInterceptors.reduce(
            (observable, interceptor) => observable.pipe(flatMap(interceptor)),
            of(param),
        )

        return currentObservable.pipe(
            map(
                ([nextUrl, nextInit = {}]: RequestInterceptorParam) =>
                    [nextUrl, nextInit] as [string, AjaxRequest],
            ),
        )
    }

    private addJsonContentType(headers: Headers = {}): Headers {
        return {
            ...headers,
            'Content-Type': 'application/json',
        }
    }

    private addFormUrlEncodedContentType(headers: Headers = {}): Headers {
        return {
            ...headers,
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }
}
