import { keyBy, uniqBy } from 'lodash'

import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
    newTmsAccountOption,
    TmsAccountOption,
    TmsAccountOptionStatus,
    TmsAccountWithName,
} from '../../common/user/TmsAccountWithName'
import * as actions from './usersActions'
import { emptyUsersPageable, initialUsersState, UsersState } from './usersState'

export const usersReducer = reducerWithInitialState(initialUsersState)
    .case(actions.load.started, (state): UsersState => ({ ...state, loadingAll: true }))
    .case(
        actions.load.failed,
        (state): UsersState => ({ ...state, loadingAll: false, users: emptyUsersPageable }),
    )
    .case(
        actions.load.done,
        (state, { result }): UsersState => ({
            ...state,
            users: result,
            loadingAll: false,
        }),
    )
    .case(actions.loadUser.started, (state): UsersState => ({ ...state, currentUser: undefined }))
    .case(actions.loadUser.failed, (state): UsersState => ({ ...state, currentUser: undefined }))
    .case(
        actions.loadUser.done,
        (state, { result }): UsersState => ({
            ...state,
            currentUser: result,
            currentUserAccounts: [],
            tmsAccountsOptions: getInitialAccounts(result.requestedTmsAccountNumbers),
        }),
    )

    .case(
        actions.loadUserAccounts.started,
        (state): UsersState => ({
            ...state,
            tmsVerificationFailed: false,
            loadUserAccountsInProgress: true,
        }),
    )
    .case(
        actions.loadUserAccounts.failed,
        (state): UsersState => ({
            ...state,
            tmsVerificationFailed: true,
            loadUserAccountsInProgress: false,
        }),
    )
    .case(
        actions.loadUserAccounts.done,
        (state, { result }): UsersState => ({
            ...state,
            loadUserAccountsInProgress: false,
            tmsVerificationFailed: false,
            currentUserAccounts: processUserAccountsResult(
                state.currentUser?.tmsAccounts ?? [],
                result,
            ),
        }),
    )

    .case(actions.update.started, (state): UsersState => ({ ...state, updateInProgress: true }))
    .case(actions.update.failed, (state): UsersState => ({ ...state, updateInProgress: false }))
    .case(
        actions.update.done,
        (state, { result }): UsersState => ({
            ...state,
            updateInProgress: false,
            currentUser: result,
            currentUserAccounts: [],
            tmsAccountsOptions: getInitialAccounts(result.requestedTmsAccountNumbers),
        }),
    )
    .case(actions.setFilters, (state, filters) => ({
        ...state,
        filters,
    }))

    .case(
        actions.verifyTmsAccounts.started,
        (state): UsersState => ({
            ...state,
            tmsVerificationFailed: false,
            tmsVerificationInProgress: true,
        }),
    )
    .case(
        actions.verifyTmsAccounts.failed,
        (state): UsersState => ({
            ...state,
            tmsVerificationFailed: true,
            tmsVerificationInProgress: false,
        }),
    )
    .case(
        actions.verifyTmsAccounts.done,
        (state, { result }): UsersState => ({
            ...state,
            tmsVerificationInProgress: false,
            tmsVerificationFailed: false,
            tmsAccountsOptions: processAccountsOptionsResult(state.tmsAccountsOptions, result),
        }),
    )

    .case(
        actions.addAndVerifyTmsAccount.started,
        (state): UsersState => ({
            ...state,
            tmsVerificationFailed: false,
            tmsVerificationInProgress: true,
        }),
    )
    .case(
        actions.addAndVerifyTmsAccount.failed,
        (state): UsersState => ({
            ...state,
            tmsVerificationFailed: true,
            tmsVerificationInProgress: false,
        }),
    )
    .case(
        actions.addAndVerifyTmsAccount.done,
        (state, { result }): UsersState => ({
            ...state,
            tmsVerificationInProgress: false,
            tmsVerificationFailed: false,
            tmsAccountsOptions: mergeTmsAccountOptions(state.tmsAccountsOptions, result),
        }),
    )

    .case(actions.resetTmsAccountsOptions, state => ({
        ...state,
        tmsAccountsOptions: state.currentUser
            ? getInitialAccounts(state.currentUser.requestedTmsAccountNumbers)
            : [],
    }))

    .case(actions.clearTmsAccountsOptions, state => ({
        ...state,
        tmsAccountsOptions: [],
    }))

const processAccountsOptionsResult = (
    current: TmsAccountOption[],
    result: TmsAccountOption[],
): TmsAccountOption[] => {
    const resultMap = keyBy(result, v => v.number)
    return current.map(a => (resultMap[a.number] ? resultMap[a.number] : a))
}

const processUserAccountsResult = (
    currentAccounts: TmsAccountWithName[],
    result: TmsAccountOption[],
): TmsAccountWithName[] => {
    const resultMap = keyBy(result, v => v.number)
    return currentAccounts.map(a =>
        resultMap[a.number] ? { ...a, name: resultMap[a.number].name } : a,
    )
}

const getInitialAccounts = (numbers: string[]) =>
    numbers.map(accountNumber => newTmsAccountOption(accountNumber))

const mergeTmsAccountOptions = (
    current: TmsAccountOption[],
    newOptions: TmsAccountOption[],
): TmsAccountOption[] =>
    uniqBy(
        [...current.filter(v => v.status !== TmsAccountOptionStatus.NotFound), ...newOptions],
        elem => [elem.number, elem.name].join(),
    )
