import React, { FC } from 'react'
import { Box, BoxProps } from '../../../../components/base/Box'
import { Button } from '../../../../components/button/Button'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'

export enum UserFormMode {
    AddBrand = 'AddBrand',
    AddPortal = 'AddPortal',
    Edit = 'Edit',
}

const FormButtons = {
    [UserFormMode.AddBrand]: {
        save: 'Add Brand',
        remove: 'Reject requested brand',
        cancel: 'Cancel',
    },
    [UserFormMode.AddPortal]: {
        save: 'Add Portal',
        remove: 'Reject requested portal',
        cancel: 'Cancel',
    },
    [UserFormMode.Edit]: {
        save: 'Save',
        remove: 'Remove',
        cancel: 'Cancel',
    },
}

interface UserFormButtonsProps extends BoxProps {
    formMode?: UserFormMode
    onSubmit: () => void
    onCancel: () => void
    onRemove?: () => void
    submitDisabled?: boolean
}

export const UserFormButtons: FC<UserFormButtonsProps> = ({
    formMode = UserFormMode.Edit,
    submitDisabled,
    onSubmit,
    onCancel,
    onRemove,
    ...props
}) => (
    <ButtonGroup justify="flex-end" alignSelf="flex-end" {...props}>
        {onRemove && (
            <Box paddingRight={16}>
                <Button onClick={onRemove} small>
                    {FormButtons[formMode].remove}
                </Button>
            </Box>
        )}
        <Button variant="secondary" onClick={onCancel} small>
            {FormButtons[formMode].cancel}
        </Button>
        <Button variant="primary" type="submit" onClick={onSubmit} disabled={submitDisabled} small>
            <Box row>{FormButtons[formMode].save}</Box>
        </Button>
    </ButtonGroup>
)
