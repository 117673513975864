import { actionCreatorFactory } from 'typescript-fsa'
import { MainBrand } from '../../common/user/Brand'
import { BrandRole } from '../../common/user/BrandRole'

const action = actionCreatorFactory('BRANDS')

export const load = action.async<undefined, Record<MainBrand, BrandRole[]>>('LOAD')

export const update = action.async<undefined, Record<MainBrand, BrandRole[]>>('UPDATE')

export const getActiveBrandRoles = action.async<undefined, Record<MainBrand, BrandRole[]>>(
    'LOAD_BRANDS_ROLES',
)
