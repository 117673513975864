import React, { FC, Children } from 'react'
import { Box, BoxProps } from '../base/Box'

export const ButtonGroup: FC<BoxProps> = ({ children, ...props }) => (
    <Box row {...props}>
        {Children.map(children, (button, index) => (
            <Box paddingLeft={index ? 16 : 0}>{button}</Box>
        ))}
    </Box>
)
