import { createGlobalStyle } from 'styled-components'
import { textColor, fontFamily } from './theme'

export const GlobalStyle = createGlobalStyle`
    *, *::after, *::before {
        box-sizing: inherit;
    }

    html, body, #root {
        height: 100%
    }
    
    html {
        overflow: hidden;
    }
   
    body {
        box-sizing: border-box;
        margin: 0;
        font-family: ${fontFamily};
        font-size: 13px;
        line-height: 1.23;
        color: ${textColor};
    }
`
