import React, { FC, useEffect, useRef, useState } from 'react'
import fetch from 'node-fetch'
import FormData from 'form-data'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { Box } from '../components/base/Box'
import { useAuth } from '../store/auth/authHooks'
import { H1 } from '../components/base/Typography'
import { Button } from '../components/button/Button'
import { appConfig } from '../config/appConfig'
import { Table, Tbody, Tr, Td } from '../components/table/Table'
import { BatchEditUsersStatus } from '../common/template/types'
import { Helmet } from 'react-helmet'

export const BatchEditUsersScreen: FC = () => {
    const user = useAuth()
    const [progress, setProgress] = useState<BatchEditUsersStatus>({ inProgress: false })
    const [file, setFile] = useState<File | null>(null)
    const [refresfing, setRefreshing] = useState(false)
    const inputRef = useRef(null)

    const getTaskStatus = () => {
        setRefreshing(true)
        fetch(`${appConfig.apiUrl}/users/batch-edit`, {
            headers: {
                'Authorization': `Bearer ${user.user.authData?.accessToken}`,
                'X-AUTH-PROVIDER': 'IDCS',
            },
        })
            .then(response => response.json())
            .then(json => {
                setProgress(json as BatchEditUsersStatus)
                setRefreshing(false)
            })
    }

    const updateErpTaskStatus = () => {
        fetch(`${appConfig.apiUrl}/users/accounts/batch-edit`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${user.user.authData?.accessToken}`,
                'X-AUTH-PROVIDER': 'IDCS',
            }
        })
            .then(res => res.json())
            .then(json => setProgress(json))
    }

    const onSubmit = () => {
        const formData = new FormData()
        formData.append('file', file)

        fetch(`${appConfig.apiUrl}/users/batch-edit`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${user.user.authData?.accessToken}`,
                'X-AUTH-PROVIDER': 'IDCS',
            },
            body: formData,
        })
            .then(res => res.json())
            .then(json => setProgress(json))
    }

    useEffect(() => {
        getTaskStatus()
    }, [])

    return (
        <Container paddingHorizontal={32} column>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <H1>Batch Edit Users</H1>
            <Instruction>
                - Please download "Export to excel" from user list to get a base for editing.
            </Instruction>
            <Instruction>
                - Only one "Batch users edit" can be running at the same time, system will not allow
                another one until previous finished. Current status can be seen below.
            </Instruction>
            <Instruction>
                - Only changes from fields from P - GW from "Export to excel" are read - editable,
                the rest will be ignored.
            </Instruction>
            <Instruction>
                - Please be advised that "Batch users edit" is only inclusive, which means it will
                only add permissions / ERP accounts, if currently assigned permissions are removed
                from excel this change will not be reflected in myAdmin.
            </Instruction>
            <ContainerInide>
                <ProgressBox>
                    Batch edit users in progress: {progress.inProgress ? 'YES' : 'NO'}
                </ProgressBox>
                <H3>Last Edit:</H3>
                {progress.lastTaskStatus ? (
                    <Table>
                        <Tbody>
                            <Tr>
                                <Td>Date:</Td>
                                <Td>
                                    {DateTime.fromMillis(
                                        progress.lastTaskStatus.date,
                                    ).toLocaleString()}
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Success:</Td>
                                <Td>{progress.lastTaskStatus.success ? 'YES' : 'NO'}</Td>
                            </Tr>
                            <Tr>
                                <Td>Message:</Td>
                                <Td>{progress.lastTaskStatus.message}</Td>
                            </Tr>
                            <Tr>
                                <Td>Filename:</Td>
                                <Td>{progress.lastTaskStatus.filename}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                ) : (
                    <Box>NONE</Box>
                )}

                <BatchEditUsersForm
                    onSubmit={event => {
                        event.preventDefault()
                        onSubmit()
                        setFile(null)
                        inputRef.current.value = null
                    }}
                >
                    <input
                        ref={inputRef}
                        type="file"
                        onChange={event =>
                            setFile(
                                event.target.files
                                    ? event.target.files.length > 0
                                        ? event.target.files[0]
                                        : null
                                    : null,
                            )
                        }
                    />

                    <ButtonsWrap>
                        <Box>
                            <Button type="submit" variant="primary" disabled={!file}>
                                Start
                            </Button>
                        </Box>
                        <Box>
                            <Button variant="primary" onClick={getTaskStatus} disabled={refresfing}>
                                Refresh
                            </Button>
                        </Box>
                    </ButtonsWrap>
                </BatchEditUsersForm>
            </ContainerInide>
        </Container>
    )
}

const Container = styled(Box)`
    max-width: 1440px;
    margin: 0 auto;
`

const ContainerInide = styled(Box)`
    padding: 0 12px;
`
const Instruction = styled(Box)`
    font-size: 14px;
    padding: 4px 0;
`

const H3 = styled.h3`
    font-weight: 300;
    font-size: 24px;
    line-height: 1.33;
    margin: 0;
`

const ProgressBox = styled(Box)`
    padding: 24px 0;
`

const BatchEditUsersForm = styled.form`
    padding-top: 24px;
`
const ButtonsWrap = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
`
