import { combineReducers } from 'redux'
import { authReducer } from './auth/authReducer'
import { AuthStateAware } from './auth/authState'
import { UsersStateAware } from './users/usersState'
import { usersReducer } from './users/usersReducer'
import { BrandsStateAware } from './brands/brandsState'
import { brandsReducer } from './brands/brandsReducer'
import { ApplicationsStateAware } from './applications/applicationsState'
import { applicationsReducer } from './applications/applicationsReducer'

type RootState = AuthStateAware & UsersStateAware & BrandsStateAware & ApplicationsStateAware

export const rootReducer = combineReducers<RootState>({
    auth: authReducer,
    users: usersReducer,
    brands: brandsReducer,
    applications: applicationsReducer,
})
