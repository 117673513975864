import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '../../components/base/Box'
import { Icon } from '../../components/icon/Icon'
import { black, borderRadius, redTextColor } from '../../styles/theme'
import { Separator } from '../../components/base/Separator'
import { TextButton } from '../../components/button/TextButton'
import {AuthUser} from "../../common/auth/AuthUser";
import { ThreeColorContainer } from './ThreeColorContainer'

interface UserDropdownProps {
    user: AuthUser
    logout: () => void
    editProfile: () => void
}

export const UserDropdown: FC<UserDropdownProps> = ({ user, editProfile, logout }) => (
    <Wrapper column>
        <ThreeColorContainer />
        <Box paddingHorizontal={24} paddingBottom={8} align="center" column>
            <Box paddingBottom={32} paddingTop={24}>
                <UserIcon name="user" />
            </Box>
            <Welcome>Welcome</Welcome>
            <UserName paddingTop={4}>{user.name}</UserName>
            <Email paddingVertical={8}>{user.email}</Email>
            <Separator paddingVertical={8} />
            <Box paddingLeft={8} fullWidth>
                <TextButton onClick={editProfile} icon="edit-profile">
                    Edit profile
                </TextButton>
            </Box>
            <Separator paddingVertical={8} />
            <Box paddingLeft={8} fullWidth>
                <TextButton onClick={logout} icon="sign-out">
                    Log out
                </TextButton>
            </Box>
        </Box>
    </Wrapper>
)

const Wrapper = styled(Box)`
    width: 248px;
    border-radius: ${borderRadius};
    overflow: hidden;
`

const Welcome = styled(Box)`
    color: ${redTextColor};
    font-size: 18px;
`

const UserName = styled(Box)`
    max-width: 100%;
    color: ${redTextColor};
    font-size: 24px;
    text-align: center;
`

const Email = styled(Box)`
    color: ${black};
    font-size: 14px;
`

const UserIcon = styled(Icon)`
    width: 90px;
    height: 90px;
    color: ${redTextColor};
`
