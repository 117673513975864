import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '../base/Box'
import { Icon, IconName } from '../icon/Icon'
import { Button } from '../button/Button'
import { brandColor, greyDark2, textColor, white } from '../../styles/theme'

interface ActionModalContentProps {
    icon: IconName
    title: string
    description: string
    buttonLabel: string
    onConfirm: () => void
}

export const ActionModalContent: FC<ActionModalContentProps> = ({
    icon,
    title,
    description,
    onConfirm,
    buttonLabel,
}) => (
    <Box column>
        <Header centered>
            <Icon color={white} name={icon} size={100} />
        </Header>
        <Content padding={32} align="center" column>
            <Title>{title}</Title>
            <Description paddingTop={16} paddingBottom={24}>
                {description}
            </Description>
            <Button onClick={onConfirm}>{buttonLabel}</Button>
        </Content>
    </Box>
)

export const Title = styled.div`
    font-weight: 300;
    font-size: 32px;
    color: ${textColor};
`

export const Description = styled(Box)`
    font-weight: 300;
    font-size: 16px;
    color: ${greyDark2};
    text-align: center;
`

const Header = styled(Box)`
    width: 400px;
    height: 200px;
    background: ${brandColor};
`

const Content = styled(Box)`
    width: 400px;
    min-height: 200px;
`
