export enum RouteLink {
    Home = '/users',
    UserDetails = '/users/:id',
    BatchEditUsers = '/batch-edit-users',
    StyleGuide = '/style-guide',
    SettingsApplications = '/settings/applications',
    SettingsBrandPermissions = '/settings/brand-permission',
}

export interface Params {
    [key: string]: string
}

export const createLink = (to: RouteLink, params: Params) =>
    Object.keys(params).reduce((str, key) => str.replace(`:${key}`, params[key]), to)
