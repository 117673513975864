import { EnumValues } from 'enum-values'

export enum PortalRole {
    PortalAdmin = 'ADMIN',
    OwnersMembers = 'OWNERS-MEMBERS',
}

const portalRoleNames: Record<PortalRole, string> = {
    [PortalRole.PortalAdmin]: 'Portal admin',
    [PortalRole.OwnersMembers]: 'Full content access',
}

export const portalRoleName = (r: PortalRole) => portalRoleNames[r]

export const allPortalsRoles: PortalRole[] = EnumValues.getValues<PortalRole>(
    PortalRole,
).sort((a, b) => portalRoleNames[a].localeCompare(portalRoleNames[b]))
