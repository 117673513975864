import { format } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { MainBrand } from '../../common/user/Brand'
import { BrandRole } from '../../common/user/BrandRole'
import { BrandRoleAssignmentOption } from '../users/BrandRoleAssignmentOption'

class BrandService {
    private rest = new BaseRestService()

    public findAll = () =>
        this.rest.getAndTransform<Record<MainBrand, BrandRole[]>>(`/config/brand-configuration`)

    public update = (payload: Record<MainBrand, BrandRole[]>) =>
        this.rest.postJson(
            format({
                pathname: `/config/brand-configuration`,
            }),
            payload,
        )

    public getActiveBrandRoles = () =>
        this.rest.getAndTransform<BrandRoleAssignmentOption[]>(`/config/active-brand-roles`)
}

export const brandsService = new BrandService()
