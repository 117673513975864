import React, { CSSProperties, FC, RefObject, useEffect, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import { useWindowSize } from '../../hooks/useWindowSize'
import { borderRadius, shadowColor, springConfig } from '../../styles/theme'
import { Box, BoxProps } from '../base/Box'
import { Portal } from '../base/Portal'
import { Overlay } from '../base/Overlay'

type HorizontalPlacement = 'left' | 'right' | 'full'
type VerticalPlacement = 'top' | 'bottom'

interface PopoverProps extends BoxProps {
    show: boolean
    el: RefObject<HTMLElement>
    horizontalPlacement?: HorizontalPlacement
    verticalPlacement?: VerticalPlacement
    onClose?: () => void

    fixed?: boolean
    topOffset?: number

    withOverlay?: boolean
}

export const Popover: FC<PopoverProps> = props => {
    const {
        children,
        el,
        show,
        onClose,
        topOffset = 0,
        horizontalPlacement = 'full',
        verticalPlacement = 'top',
        fixed = false,
        withOverlay = true,
    } = props

    const [clientRect, setClientRect] = useState<ClientRect | null>(null)
    const size = useWindowSize()
    const scrollTop = document.documentElement.scrollTop
    const documentWidth = document.documentElement.offsetWidth
    const documentHeight = document.documentElement.offsetHeight

    const ref = useRef<HTMLTableRowElement>(null)

    const selfHeight = ref.current?.clientHeight ?? 0

    useEffect(() => {
        if (el.current) {
            setClientRect(el.current.getBoundingClientRect())
        }
    }, [el, show, size])

    const containerAnimation = useSpring({
        from: {
            opacity: 0,
            transform: 'scale(0.95)',
        },
        to: {
            opacity: show ? 1 : 0,
            transform: show ? 'scale(1)' : 'scale(0.95)',
        },
        config: springConfig,
    })

    const overlayAnimation = useSpring({
        opacity: show ? 1 : 0,
        config: springConfig,
    })

    const placement = (rect: ClientRect, p: HorizontalPlacement) => {
        switch (p) {
            case 'full':
                return {
                    left: rect.left,
                    width: rect.width,
                }
            case 'left':
                return {
                    left: rect.left,
                    width: 'auto',
                }
            case 'right':
                return {
                    right: documentWidth - (rect.left + rect.width),
                    width: 'auto',
                }
        }
    }

    const top = (rect: ClientRect) => {
        const calculateTop = (rect: ClientRect) => {
            if (fixed) {
                return rect.top - topOffset
            }

            if (verticalPlacement === 'top') {
                return rect.top + scrollTop - topOffset
            } else {
                return rect.top + rect.height + scrollTop - topOffset
            }
        }

        return Math.max(Math.min(calculateTop(rect), documentHeight - selfHeight) - topOffset, 0)
    }

    const styles: CSSProperties = clientRect
        ? {
              top: top(clientRect),
              ...placement(clientRect, horizontalPlacement),
              ...containerAnimation,
              position: fixed ? 'fixed' : 'absolute',
          }
        : {}

    return (
        <Portal>
            <div style={{ pointerEvents: show ? 'all' : 'none' }}>
                <Container ref={ref} style={styles}>
                    <Box {...props}>{children}</Box>
                </Container>
                <Overlay withOverlay={withOverlay} onClick={onClose} style={overlayAnimation} />
            </div>
        </Portal>
    )
}

const Container = styled(animated.div)`
    position: absolute;
    z-index: 101;
    background-color: white;
    box-shadow: 0 5px 10px ${shadowColor};
    border-radius: ${borderRadius};
`
