
import styled from 'styled-components'
import React, { FC, useEffect } from 'react'
import { Box } from '../../components/base/Box'
import { FullscreenSpinner } from '../../components/spinner/FullscreenSpinner'
import { Table, Thead, Tbody } from '../../components/table/Table'

import { UserRowHeader } from '../users/details/components/UserRowHeader'

import { useBrands, useBrandActions } from '../../store/brands/brandsHooks'

import { allBrands, Brand } from "../../common/user/Brand";
import { BrandRole, BrandRoleBusiness, BrandRoleDocuments } from "../../common/user/BrandRole";
import { BrandRow } from './components/BrandRow'
import { Helmet } from 'react-helmet'

export interface BrandFormData {
    brand: Brand
    roles: BrandRole[]
    userAccountType?: string
}

export const SettingsBrandPermissions: FC = () => {
    const { items, loadingAll, loadBrands } = useBrands()
    const { updateBrand, updateInProgress } = useBrandActions()

    const scrollRef = React.useRef(null)
    const allBrandsSet = new Set(allBrands().map((brand) => brand.mainBrand));
    
    const brandRolesOptions = items
        ? Object.keys(items).filter(brand => allBrandsSet.has(brand)).map(key => ({
            brand: {
                mainBrand: key,
            },
            roles: {
                business: items[key].filter(
                    r =>
                        BrandRoleBusiness.includes(r.brandRole) &&
                        r.brandRole !== BrandRole.BrandAdmin,
                ),
                documents: items[key].filter(
                    r =>
                        BrandRoleDocuments.includes(r.brandRole) &&
                        r.brandRole !== BrandRole.BrandAdmin,
                ),
            },
        }))
        : []
        
    useEffect(() => {
        loadBrands()
    }, [])

    return (
        <Wrapper ref={scrollRef}>
            <Container paddingHorizontal={32} column>
                <Box paddingVertical={8} relative>
                    <Helmet>
                        <meta name="robots" content="noindex" />
                    </Helmet>
                    <h1>Default Brand Permissions</h1>
                    {(updateInProgress || loadingAll) && <FullscreenSpinner />}
                    <Table>
                        <Thead>
                            <UserRowHeader variant="Brand" />
                        </Thead>
                        <Tbody>
                            {brandRolesOptions.map((brand, index) => (
                                <BrandRow
                                    key={index}
                                    currentBrand={brand}
                                    brandRolesOptions={brandRolesOptions}
                                    onSubmit={data => updateBrand(data)}
                                />
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 100%;
    overflow: auto;
`

const Container = styled(Box)`
    max-width: 1440px;
    margin: 0 auto;
`
