import React, { FC } from 'react'
import { Th, Tr } from '../../../../components/table/Table'

interface UserRowHeaderProps {
    variant: string
}

export const UserRowHeader: FC<UserRowHeaderProps> = (props: UserRowHeaderProps) => (
    <Tr header>
        <Th style={{ width: 220 }}>{props.variant}</Th>
        <Th>Documents</Th>
        <Th>Business functions</Th>
        <Th />
    </Tr>
)

export const UserPortalRowHeader: FC<UserRowHeaderProps> = (props: UserRowHeaderProps) => (
    <Tr header>
        <Th style={{ width: 300 }}>{props.variant} name</Th>
        <Th>Permission</Th>
        <Th />
    </Tr>
)
