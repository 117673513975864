import React, { FC, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../store/auth/authHooks'
import { Icon } from '../components/icon/Icon'
import { Box } from '../components/base/Box'
import { black } from '../styles/theme'
import { Popover } from '../components/popover/Popover'
import { UserDropdown } from './components/UserDropdown'

export const User: FC = () => {
    const { user, logout, editProfile } = useAuth()
    const ref = useRef<HTMLDivElement>(null)
    const [show, setShow] = useState(false)
    const toggle = () => setShow(!show)

    if (!user.authUser) {
        return null
    }
    return (
        <Container ref={ref} align="center" row>
            <UserBox onClick={toggle} padding={8} row>
                <UserName marginRight={16}>{user.authUser?.name}</UserName>
                <Icon name="user" />
            </UserBox>
            <Popover
                el={ref}
                show={show}
                onClose={toggle}
                topOffset={-58}
                horizontalPlacement="right"
                fixed
            >
                <UserDropdown logout={logout} editProfile={editProfile} user={user.authUser} />
            </Popover>
        </Container>
    )
}

const UserName = styled(Box)`
    color: ${black};
    font-size: 13px;
`

const Container = styled(Box)`
    height: 100%;
`

const UserBox = styled(Box)`
    cursor: pointer;
`
