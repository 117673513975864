import { useSpring, useTransition } from 'react-spring'
import { springConfig } from '../../../styles/theme'

export const panelSize = { close: 270, open: 692 }

export const usePanelAnimation = (showForm: boolean) => {
    const backgroundAnimation = useSpring({
        width: showForm ? panelSize.open : panelSize.close,
        config: springConfig,
    })

    const overlayAnimation = useSpring({
        opacity: showForm ? 1 : 0,
        pointerEvents: showForm ? 'all' : 'none',
        config: springConfig,
    })

    const translateOffset = (panelSize.open - panelSize.close).toString()
    const formTransition = useTransition(showForm, null, {
        from: {
            position: 'absolute',
            opacity: 1,
            transform: `translate(-${translateOffset}px,0)`,
        },
        enter: { opacity: 1, transform: 'translate(0px,0)' },
        leave: { opacity: 0 },
        config: springConfig,
    })

    const profileTransition = useTransition(!showForm, null, {
        from: { position: 'absolute', opacity: 1 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: springConfig,
    })

    return { formTransition, profileTransition, backgroundAnimation, overlayAnimation }
}
