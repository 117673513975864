import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import spinnerImg from './assets/spinner.png'

export const Spinner: FC = () => <SpinnerImg src={spinnerImg} />

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`

export const SpinnerImg = styled.img`
    animation: ${rotate} 1s linear infinite;
    width: 24px;
    height: 24px;
    filter: invert(1);
`
