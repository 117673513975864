import { Observable, of } from 'rxjs'
import { BaseRestService, RequestInterceptorParam } from './BaseRestService'

const baseUrlRequestInterceptor = (baseUrl: string) => ([
    url,
    init = {},
]: RequestInterceptorParam): Observable<RequestInterceptorParam> => {
    if (url.startsWith('http')) {
        return of([url, init])
    }

    const param = [`${baseUrl}${url}`, init] as RequestInterceptorParam
    return of(param)
}

export const registerBaseUrlRequestInterceptor = (baseUrl: string) => {
    BaseRestService.requestInterceptors.push(baseUrlRequestInterceptor(baseUrl))
}
