import { kebabCase, uniq } from 'lodash'
import styled from 'styled-components'
import { brandColor, greyBackground, NumberValues, white } from '../../styles/theme'

type JustifyContent = 'flex-start' | 'space-between' | 'space-around' | 'flex-end' | 'center'
type AlignItems = 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'

export interface MarginBoxProps {
    margin?: NumberValues
    marginHorizontal?: NumberValues
    marginVertical?: NumberValues
    marginLeft?: NumberValues
    marginRight?: NumberValues
    marginTop?: NumberValues
    marginBottom?: NumberValues
}

export interface BoxProps extends MarginBoxProps {
    padding?: NumberValues
    paddingHorizontal?: NumberValues
    paddingVertical?: NumberValues
    paddingLeft?: NumberValues
    paddingRight?: NumberValues
    paddingTop?: NumberValues
    paddingBottom?: NumberValues

    flex?: number

    centered?: boolean
    row?: boolean
    column?: boolean
    justify?: JustifyContent
    align?: AlignItems
    alignSelf?: AlignItems

    bg?: BoxColor

    wrap?: boolean
    relative?: boolean

    fullWidth?: boolean
    fullHeight?: boolean

    maxFullWidth?: boolean
    maxFullHeight?: boolean

    className?: string
}

const boxColors = {
    white,
    gray: greyBackground,
    brand: brandColor,
}

type BoxColor = keyof typeof boxColors

export const Box = styled.div<BoxProps>(
    ({
        flex,
        row,
        column,
        centered,
        justify,
        align,
        alignSelf,
        bg,
        wrap,
        relative,

        margin,
        marginHorizontal,
        marginVertical,
        marginLeft = marginHorizontal,
        marginRight = marginHorizontal,
        marginTop = marginVertical,
        marginBottom = marginVertical,

        padding,
        paddingHorizontal,
        paddingVertical,
        paddingLeft = paddingHorizontal,
        paddingRight = paddingHorizontal,
        paddingTop = paddingVertical,
        paddingBottom = paddingVertical,
        fullWidth = false,
        fullHeight = false,
        maxFullWidth = false,
        maxFullHeight = false,
    }) => {
        const styles: string[] = []

        const displayFlex = 'display: flex;'

        if (flex) {
            styles.push(`flex: ${flex.toString()};`)
        }

        if (row) {
            styles.push(
                displayFlex,
                'flex-direction: row;',
                'justify-content: flex-start;',
                'align-items: center;',
            )
        }

        if (column) {
            styles.push(displayFlex, 'flex-direction: column;')
        }

        if (centered) {
            styles.push(
                // prettier-ignore
                displayFlex,
                'justify-content: center;',
                'align-items: center;',
            )
        }

        if (justify) {
            styles.push(displayFlex, `justify-content: ${justify};`)
        }

        if (wrap) {
            styles.push(displayFlex, 'flex-wrap: wrap;')
        }

        if (align) {
            styles.push(displayFlex, `align-items: ${align};`)
        }

        if (alignSelf) {
            styles.push(displayFlex, `align-self: ${alignSelf};`)
        }

        if (bg) {
            styles.push(`background-color: ${boxColors[bg]};`)
        }

        if (relative) {
            styles.push(`position: relative;`)
        }

        if (fullWidth) {
            styles.push(`width: 100%;`)
        }

        if (fullHeight) {
            styles.push(`height: 100%;`)
        }

        if (maxFullWidth) {
            styles.push(`max-width: 100%;`)
        }

        if (maxFullHeight) {
            styles.push(`max-height: 100%;`)
        }

        const margins = convertObjectToCss({
            margin,
            marginLeft,
            marginRight,
            marginTop,
            marginBottom,
        })

        if (margins) {
            styles.push(margins)
        }

        const paddings = convertObjectToCss({
            padding,
            paddingLeft,
            paddingRight,
            paddingTop,
            paddingBottom,
        })

        if (paddings) {
            styles.push(paddings)
        }

        return uniq(styles).join('\n')
    },
)

const convertObjectToCss = (obj: { [key: string]: number | undefined }): string | undefined => {
    const styles = Object.entries(obj)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${kebabCase(key)}: ${value!.toString()}px;`)

    if (!styles.length) {
        return undefined
    }

    return styles.join('\n')
}
