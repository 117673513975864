

import styled from 'styled-components'
import React, { FC, useRef, useState } from 'react'
import { Td, Tr } from '../../../components/table/Table'
import { Popover } from '../../../components/popover/Popover'
import { H3 } from '../../../components/base/Typography'
import { Chip } from '../../../components/chip/Chip'

import { UserRowEditHint } from '../../users/details/components/UserRowEditHint'

import { useHotkey } from '../../../hooks/useHotkey'

import { black } from '../../../styles/theme'
import {BrandRoleAssignmentOption} from "../../../services/users/BrandRoleAssignmentOption";
import {Brand, brandName, MainBrand} from "../../../common/user/Brand";
import {BrandRole, brandRoleName} from "../../../common/user/BrandRole";
import { BrandForm } from './BrandForm'

export interface BrandFormData {
    brand: Brand
    roles: BrandRole[]
    userAccountType?: string
}

interface BrandFormProps {
    brandRolesOptions: any
    currentBrand: BrandRoleAssignmentOption
    onSubmit: (data: Record<MainBrand, BrandRole[]>) => void
}

export const BrandRow: FC<BrandFormProps> = ({ brandRolesOptions, currentBrand, onSubmit }) => {
    const ref = useRef<HTMLTableRowElement>(null)

    const [show, setShow] = useState(false)
    const toggle = () => {
        setShow(!show)
    }
    useHotkey(show && 'Esc', () => toggle(), [show])

    const handleSubmit = (data: Record<MainBrand, BrandRole[]>) => {
        toggle()
        onSubmit(data)
    }
    const handleCancel = () => {
        toggle()
    }

    return (
        <>
            <Tr ref={ref} onClick={toggle} role="button" pointer>
                <Cell>
                    <UserBrandTitle>{brandName(currentBrand?.brand)}</UserBrandTitle>
                </Cell>
                <PermissionsCell>
                    {currentBrand?.roles.documents
                        .filter(role => role.active)
                        .map(role => (
                            <Chip
                                key={role.brandRole}
                                marginRight={16}
                                marginBottom={16}
                                label={brandRoleName(role.brandRole)}
                                active={role.default}
                            />
                        ))}
                </PermissionsCell>
                <PermissionsCell>
                    {currentBrand?.roles.business
                        .filter(role => role.active)
                        .map(role => (
                            <Chip
                                key={role.brandRole}
                                marginRight={16}
                                marginBottom={16}
                                label={brandRoleName(role.brandRole)}
                                active={role.default}
                            />
                        ))}
                </PermissionsCell>
                <UserRowEditHint variant="brand" canEdit />
            </Tr>

            {show && (
                <Popover el={ref} show={show} onClose={handleCancel} padding={8}>
                    <BrandForm
                        initialState={currentBrand}
                        brandRolesOptions={brandRolesOptions}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                    />
                </Popover>
            )}
        </>
    )
}

const Cell = styled(Td)`
    vertical-align: top;
    padding: 32px 16px;
`

const PermissionsCell = styled(Td)`
    padding: 36px 16px 16px;
`

const UserBrandTitle = styled(H3)`
    color: ${black};
`
