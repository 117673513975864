import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '../../../../components/base/Box'
import { TmsAccountOption, TmsAccountWithName } from '../../../../common/user/TmsAccountWithName'
import { ErrorSpan } from './UserBrandFormAccountElements'
import { UserFormUserTmsAccounts } from './UserFormUserTmsAccounts'

interface UserFormTmsAccountsProps {
    userAccounts: TmsAccountWithName[]
    loadUserAccountsInProgress: boolean
    accountsOptions: TmsAccountOption[]
    selectedAccounts: TmsAccountOption[]
    newAccountNumber: string
    tmsVerificationInProgress: boolean
    tmsVerificationFailed: boolean
    onRemove: (account: TmsAccountWithName) => void
    onSelect: (accounts: TmsAccountOption[]) => void
    onChange: (value: string) => void
    onVerify: () => void
    onAddAndVerify: (value: string) => void
}

export const UserFormTmsAccounts: FC<UserFormTmsAccountsProps> = ({
    userAccounts,
    loadUserAccountsInProgress,
    tmsVerificationFailed,
    onRemove,
}) => (
    <ScrollableContainer>
        <Box padding={4} row>
            <Box>
                {tmsVerificationFailed && (
                    <ErrorSpan>
                        There was an error in TMS connection. Accounts may not be displayed and
                        veryfied properly. Please try again later.
                    </ErrorSpan>
                )}
                <UserFormUserTmsAccounts
                    userAccounts={userAccounts}
                    loadUserAccountsInProgress={loadUserAccountsInProgress}
                    onRemove={onRemove}
                />
            </Box>
        </Box>
    </ScrollableContainer>
)

const ScrollableContainer = styled(Box)`
    max-height: 350px;
    overflow: auto;
`
