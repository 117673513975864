import React, { FC } from 'react'
import { Box, BoxProps } from '../../../../components/base/Box'
import { ButtonStyle, LinkButton } from '../../../../components/button/LinkButton'
import { Spinner } from '../../../../components/spinner/Spinner'
import { TmsAccountWithName } from '../../../../common/user/TmsAccountWithName'
import {
    Account,
    AccountIdSpan,
    AccountSpan,
    HiddableContainer,
    SpinnerContainer,
} from './UserBrandFormAccountElements'

interface UserFormUserTmsAccountsProps extends BoxProps {
    userAccounts: TmsAccountWithName[]
    loadUserAccountsInProgress: boolean
    onRemove: (account: TmsAccountWithName) => void
}

const previewID = (account: TmsAccountWithName) => {
    const accountType = account.accountType as string | null
    if (accountType && (accountType.toLowerCase() === 'machines' || accountType.toLowerCase() === 'aftermarket')) {
        const capitalize = (val: string) => val.charAt(0).toUpperCase() + val.slice(1)
        return `(ID: ${account.id} - ${capitalize(accountType.toLowerCase())})`
    }
    return `(ID: ${account.id})`
}

export const UserFormUserTmsAccounts: FC<UserFormUserTmsAccountsProps> = ({
    userAccounts,
    loadUserAccountsInProgress,
    onRemove,
}) => (
    <Box paddingTop={16} paddingBottom={userAccounts.length > 0 ? 16 : 0} relative>
        <SpinnerContainer show={loadUserAccountsInProgress}>
            <Spinner />
        </SpinnerContainer>
        <HiddableContainer show={!loadUserAccountsInProgress}>
            {userAccounts.map((account, index) => (
                <Box key={index} justify="space-between" row>
                    <Box>
                        <Account>
                            <AccountSpan>{`${account.abbreviation ?? 'TMS'} ${account.number} - ${
                                account.name
                            }`}</AccountSpan>
                            <AccountIdSpan>{previewID(account)}</AccountIdSpan>
                        </Account>
                    </Box>
                    <Box paddingLeft={16}>
                        <LinkButton
                            variant={ButtonStyle.Red}
                            onClick={() => onRemove(account)}
                            small
                        >
                            Remove
                        </LinkButton>
                    </Box>
                </Box>
            ))}
        </HiddableContainer>
    </Box>
)
