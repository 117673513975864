import { combineEpics } from 'redux-observable'
import { asyncLoadEpic } from '../../helpers/observables/asyncLoadEpic'
import { brandsService } from '../../services/brands/BrandService'
import { load, update, getActiveBrandRoles } from './brandsActions'

const loadBrandsEpic = asyncLoadEpic(load, brandsService.findAll)
const updateBrandEpic = asyncLoadEpic(update, brandsService.update)
const loadBrandActiveRolesEpic = asyncLoadEpic(
    getActiveBrandRoles,
    brandsService.getActiveBrandRoles,
)

export const brandsEpics = combineEpics(loadBrandsEpic, updateBrandEpic, loadBrandActiveRolesEpic)
