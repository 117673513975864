import React, { FC } from 'react'
import styled from 'styled-components'
import { Box, BoxProps } from './Box'

export const Wrapper: FC<BoxProps> = props => <WrapperContainer paddingHorizontal={32} {...props} />

export const WrapperContainer = styled(Box)`
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
`
