import { identity, isEqual, findIndex } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { borderRadius, fontFamily, greyLine, textColor } from '../../styles/theme'

interface SelectProps<T> {
    value: T
    options: T[]
    onChange: (selectProps: T) => void
    labelFormatter?: (v: T) => string
    small?: boolean
}

export const SimpleSelect = <T extends any>({
    value,
    options,
    onChange,
    labelFormatter = identity,
    small = false,
}: SelectProps<T>) => {
    const valueIndex = findIndex(options, v => isEqual(v, value))
    return (
        <StyledSelect
            value={valueIndex}
            small={small}
            onChange={({ target }) => onChange(options[parseInt(target.value, 10)])}
        >
            {options.map((item, index) => (
                <Option key={index} value={index}>
                    {labelFormatter(item)}
                </Option>
            ))}
        </StyledSelect>
    )
}

const StyledSelect = styled.select<{ small?: boolean }>`
    width: 100%;
    max-width: 260px;
    padding: 0 0 0 16px;
    height: ${p => (p.small ? '32px' : '38px')};
    border: 1px solid ${greyLine};
    border-radius: ${borderRadius};
    font-size: 16px;
    background: white;
    font-family: ${fontFamily};

    &:focus {
        outline: none;
        border-color: ${textColor};
    }
`

const Option = styled.option``
