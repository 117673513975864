import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import React, { FC } from 'react'
import { ToastContainer } from 'react-toastify'
import { FullscreenSpinner } from '../components/spinner/FullscreenSpinner'
import { StyleGuideScreen } from '../screens/dev/StyleGuideScreen'
import { UsersScreen } from '../screens/users/UsersScreen'
import { SettingsBrandPermissions } from '../screens/settings/SettingsBrandPermissions'
import { SettingsApplications } from '../screens/settings/SettingsApplications'
import { useAuth } from '../store/auth/authHooks'
import { store } from '../store/store'
import { GlobalStyle } from '../styles/global'
import { RouteLink } from '../Routes'
import { Wrapper } from '../components/base/Wrapper'
import { Forbidden } from '../screens/error/Forbidden'
import { BatchEditUsersScreen } from '../screens/BatchEditUsersScreen'
import { Layout } from './components/Layout'
import { Footer } from './Footer'
import { User } from './User'

const AuthenticationWrapper: FC = props => {
    const { user } = useAuth()

    if (!user.authUser || !user.authData) {
        return <FullscreenSpinner />
    }

    return props.children as any
}

export const AuthorizationWrapper: FC = props => {
    const { user } = useAuth()

    if (user.authUser?.isAdmin()) {
        return props.children as any
    }

    return (
        <Wrapper>
            <Forbidden />
        </Wrapper>
    )
}

export const App: FC = () => (
    <Provider store={store}>
        <AuthenticationWrapper>
            <BrowserRouter>
            <ToastContainer />
                <GlobalStyle />
                <Layout user={<User />} footer={<Footer />}>
                    <AuthorizationWrapper>
                        <Switch>
                            <Route path={RouteLink.StyleGuide}>
                                <StyleGuideScreen />
                            </Route>
                            <Route path={RouteLink.Home}>
                                <UsersScreen />
                            </Route>
                            <Route path={RouteLink.SettingsApplications} exact>
                                <SettingsApplications />
                            </Route>
                            <Route path={RouteLink.SettingsBrandPermissions} exact>
                                <SettingsBrandPermissions />
                            </Route>
                            <Route path={RouteLink.BatchEditUsers} exact>
                                <BatchEditUsersScreen />
                            </Route>
                            <Redirect from="/" to="/users" />
                        </Switch>
                    </AuthorizationWrapper>
                </Layout>
            </BrowserRouter>
        </AuthenticationWrapper>
    </Provider>
)
