import styled from 'styled-components'
import React, { FC, forwardRef, RefAttributes } from 'react'
import { greyLight, greyDark, shadowColor } from '../../styles/theme'
import { Icon } from '../icon/Icon'
import { Stylable } from '../types/Stylable'

interface InputProps extends Stylable, RefAttributes<HTMLInputElement> {
    checked?: boolean
    disabled?: boolean
    title?: string
    subTitle?: string
    areSomeChecked?: boolean
    onChange: (value: boolean) => void
}

export const Checkbox: FC<InputProps> = forwardRef(
    ({ checked: value, disabled, areSomeChecked, onChange, title, subTitle, className }, ref) => (
        <Label>
            <StyledInput
                ref={ref}
                checked={value}
                disabled={disabled}
                onChange={() => onChange(!value)}
                className={className}
                type="checkbox"
            />
            <StyledCheckbox checked={value} disabled={disabled}>
                {areSomeChecked ? (
                    <SomeCheckedIcon />
                ) : (
                    <Icon className="check-icon" name="check" color={greyDark} />
                )}
            </StyledCheckbox>

            <Title disabled={disabled}>{title}</Title>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </Label>
    ),
)

const Label = styled.label`
    display: flex;
    cursor: pointer;
    padding: 4px 0;
    align-items: center;
`

const StyledInput = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

const SomeCheckedIcon = styled.div`
    width: 14px;
    height: 14px;
    background: ${greyDark};
    border-radius: 2px;
    margin: 4px 0 0 4px;
`

const StyledCheckbox = styled.div<{ disabled?: boolean; checked?: boolean }>`
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid #d7d7da;
    border-radius: 4px;
    transition: all 150ms;
    background: ${props => (props.disabled ? '#f7f7f8;' : 'transparent')};

    ${StyledInput}:focus + & {
        box-shadow: 0 0 0 2px ${shadowColor};
    }

    .check-icon {
        display: inline;
    }

    .check-icon,
    ${SomeCheckedIcon} {
        visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    }
`

const Title = styled.span<{ disabled?: boolean }>`
    vertical-align: middle;
    display: inline-block;
    padding-left: 8px;
    color: ${props => (props.disabled ? '#AFAFB4' : '#383844')};
`
const SubTitle = styled.span`
    vertical-align: middle;
    display: inline-block;
    color: ${greyLight};
    font-size: 12px;
    padding-left: 8px;
`
