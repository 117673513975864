import React, { FC } from 'react'
import { range } from 'lodash'
import { Box } from '../base/Box'
import { Button } from '../button/Button'

export interface Page {
    size: number
    index: number
    totalElements: number
}

export interface PaginationProps {
    page: Page
    onChange: (page: Page) => void
    maxButtons?: number
}

export const Pagination: FC<PaginationProps> = ({ page, maxButtons = 9, onChange }) => {
    const totalPages = Math.ceil(page.totalElements / page.size)
    const totalButtons = Math.min(maxButtons, totalPages)
    const leftRightForIndex = (
        minValue: number,
        maxValue: number,
        current: number,
        bufforSize: number,
    ): [number, number] => {
        if (maxValue - minValue <= bufforSize) {
            return [minValue, maxValue]
        }
        if (current + bufforSize / 2 > maxValue) {
            return [maxValue - bufforSize, maxValue]
        }
        if (current - bufforSize / 2 < minValue) {
            return [minValue, minValue + bufforSize]
        }
        const start = Math.floor(current - bufforSize / 2)
        return [start, start + bufforSize]
    }
    const [leftPage, rightPage] = leftRightForIndex(0, totalPages, page.index, totalButtons)
    const pageItems = range(leftPage, rightPage)
    const jump = (pageIndex: number) => {
        onChange({ ...page, index: pageIndex })
    }

    const pageButton = (p: number, index: number) => {
        const isActive = p === page.index

        if (index === 0) {
            return <PageButton onClick={() => jump(0)} isActive={isActive} label="1" />
        }

        if (index === pageItems.length - 1) {
            return (
                <PageButton
                    onClick={() => jump(totalPages - 1)}
                    isActive={isActive}
                    label={`${totalPages}`}
                />
            )
        }

        if (index === 1 && p > 1) {
            return <MoreButton onClick={() => jump(p)} />
        }

        if (index === pageItems.length - 2 && p < totalPages - 2) {
            return <MoreButton onClick={() => jump(p)} />
        }

        return <PageButton label={`${p + 1}`} onClick={() => jump(p)} isActive={p === page.index} />
    }

    return (
        <Box row>
            <Box marginRight={8}>
                <NavButton
                    onClick={() => jump(page.index - 1)}
                    disabled={page.index === 0}
                    label="Prev"
                />
            </Box>
            {pageItems.map((p, index) => (
                <Box key={index} marginRight={8}>
                    {pageButton(p, index)}
                </Box>
            ))}
            <NavButton
                onClick={() => jump(page.index + 1)}
                disabled={page.index === totalPages - 1}
                label="Next"
            />
        </Box>
    )
}

interface NavButtonProps {
    onClick(): void
    disabled?: boolean
    label: string
}

interface MoreButtonProps {
    onClick(): void
}

interface PageButtonProps {
    onClick(): void
    isActive: boolean
    label: string
}

const NavButton: FC<NavButtonProps> = ({ onClick, disabled, label }) => (
    <Button onClick={onClick} disabled={disabled} small>
        {label}
    </Button>
)

const PageButton: FC<PageButtonProps> = ({ onClick, isActive, label }) => (
    <Button onClick={onClick} variant={isActive ? 'primary' : 'flat'} small>
        {label}
    </Button>
)

const MoreButton: FC<MoreButtonProps> = ({ onClick }) => (
    <Button onClick={onClick} variant="flat" small>
        ...
    </Button>
)
