import React, { FC } from 'react'
import { Box } from '../../../components/base/Box'
import { Form } from '../../../components/form/Form'
import { Tooltip } from '../../../components/tooltip/Tooltip'
import { Checkbox } from '../../../components/form/Checkbox'
import { Switch } from '../../../components/form/Switch'
import { H3 } from '../../../components/base/Typography'
import { useForm } from '../../../hooks/form/useForm'
import { UserBrandPermissionsGuidance } from '../../users/details/components/UserBrandPermissionsGuidance'
import { UserFormButtons } from '../../users/details/components/UserFormButtons'
import {BrandRole, brandRoleName} from "../../../common/user/BrandRole";
import {Brand, brandName, MainBrand} from "../../../common/user/Brand";

export interface BrandFormData {
    brand: Brand
    roles: BrandRole[]
    userAccountType?: string
}

interface BrandFormProps {
    initialState: BrandFormData
    onSubmit: (data: Record<MainBrand, BrandRole[]>) => void
    onCancel: () => void
}

export const BrandForm: FC<BrandFormProps> = ({ initialState, onSubmit, onCancel }) => {
    const { updateField, values, submitHandler, reset } = useForm<BrandFormData>(
        {},
        {
            initialState: {
                ...initialState,
            },
        },
    )

    const updateRoles = ({ roleType, roleName, field }) => {
        const newRoles = values.roles ? { ...values.roles } : {}

        const newRolesGroup = newRoles[roleType]

        const index = newRolesGroup.findIndex(r => r.brandRole === roleName)

        newRolesGroup[index][field] = !newRolesGroup[index][field]

        if (field === 'active' && newRolesGroup[index].default) {
            newRolesGroup[index].default = false
        }

        newRoles[roleType] = newRolesGroup

        updateField('roles', newRoles)
    }

    const handleSubmit = (data: BrandFormData) => {
        onSubmit({
            brand: data.brand.mainBrand,
            configuration: [...data.roles.documents, ...data.roles.business],
        })
        reset()
    }

    const handleCancel = () => {
        reset()
        onCancel()
    }

    return (
        <Form onSubmit={submitHandler(handleSubmit)}>
            <Box>
                <Box padding={24} justify="space-between" align="flex-start" row>
                    <Box flex={1} paddingRight={16} style={{ maxWidth: '210px' }}>
                        <H3>{brandName(values.brand)}</H3>
                    </Box>
                    <Box flex={1} paddingRight={16}>
                        <Box marginBottom={16}>
                            <Tooltip
                                icon="info"
                                title="Permission Guidance"
                                content={
                                    <UserBrandPermissionsGuidance
                                        accountType={initialState.userAccountType}
                                    />
                                }
                                width="476px"
                            />
                        </Box>
                        <Box>
                            <H3>Documents</H3>
                            <Box>
                                <span style={{ marginRight: '20px' }}>Active</span>
                                <span>Selected by default</span>
                            </Box>
                            {values.roles.documents.map(role => (
                                <Box key={role.brandRole} row>
                                    <Box marginRight={16}>
                                        <Switch
                                            checked={role.active}
                                            onChange={() =>
                                                updateRoles({
                                                    roleType: 'documents',
                                                    roleName: role.brandRole,
                                                    field: 'active',
                                                })
                                            }
                                        />
                                    </Box>
                                    <Box>
                                        <Checkbox
                                            key={role.brandRole}
                                            title={brandRoleName(role.brandRole)}
                                            checked={role.default}
                                            disabled={!role.active}
                                            onChange={() =>
                                                updateRoles({
                                                    roleType: 'documents',
                                                    roleName: role.brandRole,
                                                    field: 'default',
                                                })
                                            }
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    <Box flex={1} paddingRight={16} paddingTop={40}>
                        <Box>
                            <H3>Business Functions</H3>
                            <Box>
                                <span style={{ marginRight: '20px' }}>Active</span>
                                <span>Selected by default</span>
                            </Box>
                            {values.roles.business.map(role => (
                                <Box key={role.brandRole} row>
                                    <Box marginRight={16}>
                                        <Switch
                                            checked={role.active}
                                            onChange={() =>
                                                updateRoles({
                                                    roleType: 'business',
                                                    roleName: role.brandRole,
                                                    field: 'active',
                                                })
                                            }
                                        />
                                    </Box>
                                    <Box>
                                        <Checkbox
                                            key={role.brandRole}
                                            title={brandRoleName(role.brandRole)}
                                            checked={role.default}
                                            disabled={!role.active}
                                            onChange={() =>
                                                updateRoles({
                                                    roleType: 'business',
                                                    roleName: role.brandRole,
                                                    field: 'default',
                                                })
                                            }
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box flex={1} justify="flex-end">
                        <UserFormButtons paddingBottom={16} onCancel={handleCancel} />
                    </Box>
                </Box>
            </Box>
        </Form>
    )
}
