import { Brand, MainBrand } from '../../common/user/Brand'
import { BrandRole } from '../../common/user/BrandRole'

export interface BrandRoleAssignmentOption {
    brand: Brand
    roles: {
        documents: BrandRole[]
        business: BrandRole[]
    }
}

export const firstBrandRoleAssignmentOption = (
    options: BrandRoleAssignmentOption[],
): BrandRoleAssignmentOption | undefined => {
    if (!options.length) {
        return undefined
    }
    return {
        roles: {
            documents: [],
            business: [],
        },
        brand: options[0].brand,
    }
}

export const hasAccessToBrandInOptions = (
    options: BrandRoleAssignmentOption[],
    mainBrand: MainBrand,
): boolean => !!options.find(o => o.brand.mainBrand === mainBrand)
