import { Route, Switch } from 'react-router-dom'
import React, { FC, useEffect } from 'react'
import { RouteLink } from '../../Routes'
import { useUsers } from '../../store/users/usersHooks'
import { BatchEditUsersScreen } from '../BatchEditUsersScreen'
import { usersRequestDefault } from '../../common/user/UserRequest'
import { UserDetailsScreen } from './UserDetailsScreen'
import { UserListScreen } from './UserListScreen'

export const UsersScreen: FC = () => {
    const { loadUsers } = useUsers()

    useEffect(() => {
        loadUsers(usersRequestDefault)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Switch>
            <Route path={RouteLink.UserDetails} exact>
                <UserDetailsScreen />
            </Route>
            <Route path={RouteLink.Home} exact>
                <UserListScreen />
            </Route>
            <Route path={RouteLink.BatchEditUsers} exact>
                <BatchEditUsersScreen />
            </Route>
        </Switch>
    )
}
