import { actionCreatorFactory } from 'typescript-fsa'
import { AuthData } from '../../services/auth/AuthService'

const action = actionCreatorFactory('AUTH')

export const logout = action<{ purge: boolean; idToken?: string }>('LOGOUT')

export const editProfile = action('EDIT_PROFILE')

export const setToken = action<Partial<AuthData>>('SET_B2C_TOKEN')
