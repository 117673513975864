import React, { FC } from 'react'
import { Modal } from '../../components/modal/Modal'
import { ActionModalContent } from '../../components/modal/ActionModalContent'

interface DeleteUserConfirmationModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

export const DeleteUserConfirmationModal: FC<DeleteUserConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
}) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ActionModalContent
            title="Delete user"
            description="User will be deleted. This operation is irreversible."
            buttonLabel="Delete"
            icon="delete-round"
            onConfirm={onConfirm}
        />
    </Modal>
)
